import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  CssBaseline,
  TextField,
  Typography,
  Avatar,
  Container,
  CircularProgress,
  Button,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import history from "../../helpers/history";
import {
  axiosGetCompaniesUsefulLists,
  axiosCreateCompany,
} from "../../helpers/servicesModule";
import { isMailFormat } from "../../helpers/validationFunctions";
import Error from "../../components/MessageBoxes/Error/Error";
import Success from "../../components/MessageBoxes/Success/Success";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(6),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(0, 0, 0),
  },
  green: {
    backgroundColor: green[500],
  },
  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
}));

const FormNewUser = () => {
  const classes = useStyles();

  const initialState = {
    username: "",
    email: "",
    companyName: "",
  };

  const errorState = {
    state: false,
    message: "",
  };
  const successState = {
    state: false,
    message: "",
  };

  const [newUserData, updateNewUserData] = useState(initialState);

  const [blurOnCompanyField, updateBlurOnCompanyField] = useState(false);
  const [companyError, updateCompanyError] = useState(errorState);

  const [usernamesRegistered, updateUsernamesRegistered] = useState([]);
  const [blurOnUsernameField, updateBlurOnUsernameField] = useState(false);
  const [changeOnUsernameField, updateChangeOnUsernameField] = useState(false);
  const [usernameError, updateUsernameError] = useState(errorState);

  const [emailError, updateEmailError] = useState(errorState);
  const [focusOnEmailField, updateFocusOnEmailField] = useState(false);
  const [blurOnEmailField, updateBlurOnEmailField] = useState(false);

  const [loading, updateLoading] = useState(false);
  const [error, updateError] = useState(errorState);
  const [success, updateSuccess] = useState(successState);


  let { username, email, companyName } = newUserData;

  useEffect(() => {
    const auxAsyncFunction = async () => {
      let response = await axiosGetCompaniesUsefulLists(history);

      if (response.error) {
        history.push("/ingresar");
      } else {
        updateUsernamesRegistered(response);
      }
    };

    auxAsyncFunction();
  }, []);

  useEffect(() => {
    if (newUserData.companyName.trim().length > 0) {
      updateCompanyError({
        state: false,
        message: "",
      });
    }

    if (changeOnUsernameField) {
      setTimeout(() => {
        if (usernamesRegistered.usernames.includes(newUserData.username)) {
          updateUsernameError({
            ...usernameError,
            state: true,
            message: "Código de empresa no disponible.",
          });
        } else {
          updateUsernameError({
            ...usernameError,
            state: false,
            message: "",
          });
        }
      }, 700);
    }

    if (focusOnEmailField) {
      setTimeout(() => {
        if (isMailFormat(newUserData.email)) {
          updateEmailError({
            state: false,
            message: "",
          });
        } else {
          updateEmailError({
            state: true,
            message: "Formato inválido.",
          });
        }
      }, 700);
    }
  }, [newUserData]);

  useEffect(() => {
    if (blurOnCompanyField && !newUserData.companyName) {
      updateCompanyError({
        state: true,
        message: "Campo vacío.",
      });
    }

    if (blurOnUsernameField && !newUserData.username) {
      updateUsernameError({
        state: true,
        message: "Campo vacío.",
      });
    }

    if (blurOnEmailField && !newUserData.email) {
      updateEmailError({
        state: true,
        message: "Campo vacío.",
      });
    }
  }, [blurOnCompanyField, blurOnUsernameField, blurOnEmailField]);

  const changeHandler = (event) => {
    if (event.target.id === "username") {
      updateChangeOnUsernameField(true);
    }

    updateNewUserData({
      ...newUserData,
      [event.target.name]: event.target.value,
    });
  };

  const focusOnEmailFieldHandler = () => {
    updateFocusOnEmailField(true);
  };

  const blurHandler = (event) => {
    cleanSubmitError();

    switch (event.target.name) {
      case "username":
        updateBlurOnUsernameField(true);
        break;
      case "email":
        updateBlurOnEmailField(true);
        break;
      case "companyName":
        updateBlurOnCompanyField(true);
        break;
    }
  };

  const isSubmitDisabled = () => {
    let response = true;

    if ( !(companyError.state || usernameError.state || emailError.state) ) {
      if (
        newUserData.companyName &&
        newUserData.username &&
        newUserData.email
      ) {
        response = false;
      }
    }

    return response;
  };

  const submitHandler = (event) => {
    event.preventDefault();
    cleanSubmitError();
    updateLoading(true);

    if ( !isSubmitDisabled() ) {
      // The data is regrouped...
      const newUser = {
        companyName: newUserData.companyName,
        username: newUserData.username,
        email: newUserData.email,
      };

      setTimeout(async () => {
        let response = await axiosCreateCompany(history, newUser);
        updateLoading(false);

        if (response && response.error) {
          updateError({
            state: true,
            message: response.error.message,
          });
        } else if (response.success) {
          updateSuccess({
            state: true,
            message: response.success.message,
          });

          setTimeout(() => {
            history.push(`/empresas`);
          }, 3000);
        }
      }, 2000);
    }
  };

  const cleanSubmitError = () => {
    updateError({ state: false, message: "" });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={success.state ? classes.green : ""}>
          <i className="fas fa-university" />
        </Avatar>
        <Typography component="h1" variant="h5">
          Crear empresa
        </Typography>
        <form className={classes.form}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="companyName"
            label="Nombre de la empresa"
            helperText={companyError.message}
            error={companyError.state}
            value={companyName}
            type="text"
            id="companyName"
            autoFocus
            onBlur={blurHandler}
            onChange={changeHandler}
          />
          <TextField
            type="number"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Código de empresa"
            helperText={usernameError.message}
            error={usernameError.state}
            value={username}
            name="username"
            onBlur={blurHandler}
            onChange={changeHandler}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="email"
            label="E-mail"
            helperText={emailError.message}
            error={emailError.state}
            value={email}
            type="email"
            id="email"
            onFocus={focusOnEmailFieldHandler}
            onBlur={blurHandler}
            onChange={changeHandler}
          />

          {success.state ? (
            <Success message={success.message} />
          ) : (
            <>
              {error.state && <Error message={error.message} />}
              <div className="form-buttons">
                {loading ? (
                  <Button
                    disabled={true}
                    variant="contained"
                    color="inherit"
                    fullWidth
                  >
                    Cancelar
                  </Button>
                ) : (
                  <Link to="/empresas" className="plain-text full-width">
                    <Button
                      variant="contained"
                      color="inherit"
                      fullWidth
                      disabled={loading}
                    >
                      Cancelar
                    </Button>
                  </Link>
                )}

                <div className="ml-20px full-width">
                  <Button
                    disabled={loading || isSubmitDisabled() }
                    type="submit"
                    fullWidth
                    mt={8}
                    variant="contained"
                    color="primary"
                    onBlur={blurHandler}
                    // TODO no anda evento onBlur del boton, no limpia error
                    className={classes.submit + " transition"}
                    onClick={submitHandler}
                  >
                    {loading ? (
                      <div className={classes.root}>
                        <CircularProgress size={24} />
                      </div>
                    ) : (
                      "CREAR"
                    )}
                  </Button>
                </div>
              </div>
            </>
          )}
        </form>
      </div>
    </Container>
  );
};

export default FormNewUser;
