import axios from 'axios';
import history from '../helpers/history';
import { 
    getSessionToken, 
    removeSessionToken, 
    setExpiredSession 
} from '../helpers/sessionStorage';

require('dotenv').config();


export const axiosAPIForm = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT_KEY,
});

export const axiosAPIJSON = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT_KEY,
});

const requestInterceptor = (config, isContentTypeJson) => {    
    const SESSION_TOKEN = getSessionToken();
    if (SESSION_TOKEN) {
        config.headers['Authorization'] = `Bearer ${SESSION_TOKEN}`;
    }
    if (isContentTypeJson === true)
        config.headers['Content-Type'] = 'application/json';
    else if(isContentTypeJson === false)
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset = UTF-8';
    
    return config;
}

axiosAPIForm.interceptors.request.use(
    config => {
        return requestInterceptor(config, false);
    },
    error => {
        Promise.reject(error)
    });

axiosAPIJSON.interceptors.request.use(    
    config => {
        return requestInterceptor(config, true);
    },
    error => {
        Promise.reject(error)
    });

const errorInterceptor = (error) => {
    if (!error.response) {
        return (
            new Promise((resolve, reject) => {
                reject(error);
            })
        )
    } else if ( !(error.request.responseURL.includes('/auth/login') ) && ( error.response.status === 401 || error.response.status === 400 )) {  
        setExpiredSession(true);
        removeSessionToken();
        if (history) { history.push('/ingresar') }
    } else {
        return (
            new Promise((resolve, reject) => {
                reject(error);
            })
        )
    }
}

axiosAPIForm.interceptors.response.use(
    response => {
        return (
            new Promise((resolve, reject) => {
                resolve(response);
            })
        )
    },
    error => {
        return errorInterceptor(error);
    }
)

axiosAPIJSON.interceptors.response.use(
    response => {
        return (
            new Promise((resolve, reject) => {
                resolve(response);
            })
        )
    },
    error => {
        return errorInterceptor(error);
    }
)