import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { green, grey, blue, yellow } from "@material-ui/core/colors";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  Paper,
  IconButton,
  Button,
  Tooltip,
  LinearProgress,
  Tabs,
  Tab,
  Typography,
  FormControlLabel,
  Checkbox,
  Grid,
} from "@material-ui/core";
import Autocomplete from "../../components/Autocomplete/Autocomplete";
import {
  FirstPage as FirstPageIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage as LastPageIcon,
} from "@material-ui/icons";
import {
  axiosAuditFind,
  axiosGetJSONAudit,
  axiosGetCSVAudit,
  axiosGetAuditsCountByStatus,
  axiosGetCompaniesUsefulLists,
} from "../../helpers/servicesModule";
import { dateSeparator } from "../../helpers/dates";
import { getStringTime } from "../../helpers/times";
import CSVModal from "../../components/Modals/CSVModal";
import JSONModal from "../../components/Modals/JSONModal";
import ProgressBarStacked from "../../components/ProgressBar/ProgressBarStacked";
import {
  stateIndexToText,
  stateTextToIndex,
  translateStateToEnglish,
  translateStateToSpanish,
} from "../../helpers/statusTabsTools";

export default function AuditTable({ ID_COMPANY_AUDIT, STATUS, history }) {
  const classes = useStyles2();
  const userIsAdmin = useSelector((state) => state.user.userData.isAdmin);

  const [firstCallAudits, setFirstCallAudits] = useState(true);
  const [firstCallCounts, setFirstCallCounts] = useState(true);

  const [idCompanyAudit, setIdCompanyAudit] = useState(ID_COMPANY_AUDIT);
  const [audits, setAudits] = useState([]);

  const [CSVShow, setCSVShow] = useState(null);
  const [JSONShow, setJSONShow] = useState(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalAudits, updateTotalAudits] = useState(0);

  const [seeClosings, updateSeeClosings] = useState(true);
  const [seeMovements, updateSeeMovements] = useState(true);

  const auxValueTab = STATUS
    ? stateTextToIndex(translateStateToEnglish(STATUS))
    : 0;
  const [valueTab, setValueTab] = useState(auxValueTab);
  const [auditsCountByStatus, updateAuditsCountByStatus] = useState({});

  const [openJSON, setOpenJSON] = useState(false);
  const [openCSV, setOpenCSV] = useState(false);

  const [arrivedOptions, setArrivedOptions] = useState(false);
  const [options, setOptions] = useState([]); // Autocomplete
  const [value, setValue] = useState(""); // Autocomplete
  const [inputValue, setInputValue] = useState(""); // Autocomplete

  const [didMount, setDidMount] = useState(false); 

  useEffect(() => {
   setDidMount(true);
   return () => setDidMount(false);
  }, [])  

  useEffect(() => {
    const auxAsyncFunction = async () => {
      let usefulLists = await axiosGetCompaniesUsefulLists();

      if (!usefulLists.error) {
        const idCompaniesList = usefulLists.usernames;

        if (usefulLists.labels) {
          usefulLists.labels = ["Todas las empresas", ...usefulLists.labels];
          setOptions(usefulLists);
        }

        if (
          idCompaniesList &&
          !idCompaniesList.some((id) => id === ID_COMPANY_AUDIT)
        ) {
          window.history.replaceState(
            null,
            null,
            `/auditoria/${translateStateToSpanish(stateIndexToText(valueTab))}`
          );
          setIdCompanyAudit("");
        }
      }
    };

    if (userIsAdmin) {
      auxAsyncFunction();
    }
  }, []);

  useEffect(() => {
    if (userIsAdmin && options.labels) {
      const indexValue = options.labels.findIndex(
        (string) => extractCompanyID(string) === ID_COMPANY_AUDIT
      );
      setArrivedOptions(true);
      setTimeout(() => {
        setArrivedOptions(false);
      }, 2000);
      if (indexValue > -1) {
        setValue(options.labels[indexValue]);
        setInputValue(options.labels[indexValue]);
      } else {
        setInputValue(options.labels[0]);
        setValue(options.labels[0]);
      }
    }
  }, [options]);

  useEffect(() => {
    if (userIsAdmin) {
      if (inputValue !== "" && inputValue !== "Todas las empresas") {
        let auxIdAudit = extractCompanyID(inputValue);
        if (
          !arrivedOptions &&
          options.usernames.some((stringID) => stringID === auxIdAudit)
        ) {
          if (page !== 0) {
            setPage(0);
          }
          setIdCompanyAudit(auxIdAudit);
          window.history.replaceState(
            null,
            null,
            `/auditoria/${auxIdAudit}/${translateStateToSpanish(
              stateIndexToText(valueTab)
            )}`
          );
        }
      } else {
        if (!firstCallAudits) {
          if (page !== 0) {
            setPage(0);
          }
          window.history.replaceState(
            null,
            null,
            `/auditoria/${translateStateToSpanish(stateIndexToText(valueTab))}`
          );
          setIdCompanyAudit("");
        }
      }
    }
  }, [inputValue]);

  useEffect(() => {
    window.history.replaceState(
      null,
      null,
      `/auditoria/${
        idCompanyAudit ? idCompanyAudit + "/" : ""
      }${translateStateToSpanish(stateIndexToText(valueTab))}`
    );
  }, [valueTab]);

  useEffect(() => {
    getAudit();
  }, [valueTab, seeClosings, seeMovements, page, rowsPerPage, idCompanyAudit]);

  useEffect(() => {
    const auxAsyncFunction = async () => {
      let companyID = "";
      if (userIsAdmin) {
        if (ID_COMPANY_AUDIT && firstCallCounts) {
          companyID = ID_COMPANY_AUDIT;
          setFirstCallCounts(false);
        } else {
          companyID = extractCompanyID(inputValue);
        }
      } else {
        companyID = idCompanyAudit; // TODO VER
      }
      let response = await axiosGetAuditsCountByStatus(
        companyID,
        seeClosings,
        seeMovements
      );
      let states = {};

      if (response && !response.error) {
        response.data.map((objDataState) => {
          states[objDataState.status] = objDataState.count;
        });

        updateAuditsCountByStatus(states);
      }
      setTimeout(() => {
        if (!seeClosings && !seeMovements) {
          updateAuditsCountByStatus({});
        }
      }, 1000);
    };

    auxAsyncFunction();
  }, [seeClosings, seeMovements, idCompanyAudit]);

  const extractCompanyID = (option) => {
    let companyID = "";

    if (option === "Todas las empresas" || option === "") {
      companyID = "";
    } else if (option) {
      let opening = option.lastIndexOf("(");
      let closing = option.lastIndexOf(")");
      if (opening > -1 && closing > -1) {
        companyID = option.slice(opening + 1, closing);
      }
    }

    return companyID;
  };

  const getAudit = async () => {
    let companyID = "";

    if (userIsAdmin) {
      if (ID_COMPANY_AUDIT && firstCallAudits) {
        companyID = ID_COMPANY_AUDIT;
        window.history.replaceState(
          null,
          null,
          `/auditoria/${ID_COMPANY_AUDIT}/${translateStateToSpanish(
            stateIndexToText(valueTab)
          )}`
        );
      } else {
        companyID = extractCompanyID(inputValue);
      }
    } else {
      companyID = idCompanyAudit; // TODO VER
    }
    setFirstCallAudits(false);

    let response = await axiosAuditFind(
      companyID,
      stateIndexToText(valueTab),
      seeClosings,
      seeMovements,
      page,
      rowsPerPage
    );

    if (response) {
      if (!response.error) {
        setAudits(response.audits);
        updateTotalAudits(response.total);
      }
    }

    setTimeout(() => {
      if (!seeClosings && !seeMovements) {
        setAudits([]);
      }
    }, 1000);
  };

  const handleChangeTab = (event, newValueTab) => {
    setValueTab(newValueTab);
    setPage(0);
  };

  const columns = [
    { id: "idColumna1", label: "JobID", minWidth: 25, align: "center" },
    { id: "idColumna2", label: "Tipo", minWidth: 25, align: "center" },
    { id: "idColumna3", label: "Empresa", minWidth: 50, align: "center" },
    { id: "idColumna4", label: "Local", minWidth: 50, align: "center" },
    { id: "idColumna5", label: "Caja", minWidth: 50, align: "center" },
    { id: "idColumna6", label: "Cant. Tickets", minWidth: 50, align: "center" },
    {
      id: "idColumna7",
      label: "Estado",
      minWidth: 50,
      maxWidth: 50,
      align: "center",
    },
    { id: "idColumna8", label: "Fecha inicio", minWidth: 106, align: "center" },
    { id: "idColumna9", label: "Duración", minWidth: 50, align: "center" },
    { id: "idColumna10", label: "Reintentos", minWidth: 50, align: "center" },
    { id: "idColumna11", label: "Acciones", minWidth: 50, align: "center" },
  ];

  const rows = [...audits];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if ( !didMount ) {  return null }

  return (
    <>
      <Paper className={classes.paperTitle}>
        <div className="flex-space-between">
          <div className="search-container">
            {userIsAdmin && (
              <Autocomplete
                value={value}
                inputValue={inputValue}
                setValue={setValue}
                setInputValue={setInputValue}
                options={options.labels ? options.labels : []}
              />
            )}
          </div>
          <div className="flex-align-items-center">
            <Tooltip title="Recargar datos" arrow placement="left">
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={() => {
                  getAudit(idCompanyAudit);
                }}
              >
                <i className="fas fa-redo"></i>
              </Button>
            </Tooltip>
          </div>
        </div>
      </Paper>
      <TableContainer component={Paper}>
        <div className="flex-space-between full-width">
          <div className={classes.rootTabs}>
            <div className={classes.bgColor2e1534}>
              <StyledTabs
                value={valueTab}
                onChange={handleChangeTab}
                aria-label="styled tabs example"
              >
                <StyledTab
                  label={`Completados ${
                    auditsCountByStatus.completed !== undefined
                      ? "(" + auditsCountByStatus.completed + ")"
                      : ""
                  }`}
                />

                <StyledTab
                  label={`Activos ${
                    auditsCountByStatus.active !== undefined
                      ? "(" + auditsCountByStatus.active + ")"
                      : ""
                  }`}
                />

                <StyledTab
                  label={`En espera ${
                    auditsCountByStatus.waiting !== undefined
                      ? "(" + auditsCountByStatus.waiting + ")"
                      : ""
                  }`}
                />

                <StyledTab
                  label={`Fallo ${
                    auditsCountByStatus.failed !== undefined
                      ? "(" + auditsCountByStatus.failed + ")"
                      : ""
                  }`}
                />

                <StyledTab
                  label={`Demorado ${
                    auditsCountByStatus.delayed !== undefined
                      ? "(" + auditsCountByStatus.delayed + ")"
                      : ""
                  }`}
                />
              </StyledTabs>
              <Typography className={classes.padding} />
            </div>
          </div>
          <div className="display-flex">
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={seeClosings}
                    onChange={() => {
                      updateSeeClosings(!seeClosings);
                      setPage(0);
                    }}
                  />
                }
                label="Ver cierres"
              />
            </div>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={seeMovements}
                    onChange={() => {
                      updateSeeMovements(!seeMovements);
                      setPage(0);
                    }}
                  />
                }
                label="Ver movimientos"
              />
            </div>
          </div>
        </div>
        <Table className={classes.table} aria-label="custom pagination table">
          <TableHead className={classes.head}>
            <TableRow>
              {columns.map((column, i) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <strong>{column.label}</strong>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length === 0 ? (
              <TableRow style={{ width: 500 }}>
                <TableCell align="center" colSpan={columns.length}>
                  {"No hay resultados para esta busqueda."}
                </TableCell>
              </TableRow>
            ) : (
              rows.map((row, i) => (
                <TableRow key={row._id}>
                  <TableCell style={{ width: 50 }} align="left">
                    {row.jobId}
                  </TableCell>
                  <TableCell style={{ width: 80 }} align="center">
                    {row.isClosing ? (
                      <i className="far fa-times type-icon" />
                    ) : (
                      <i className="fas fa-folder-open type-icon" />
                    )}
                  </TableCell>
                  <TableCell style={{ width: 100 }} align="center">
                    {row.username}
                  </TableCell>
                  <TableCell style={{ width: 90 }} align="center">
                    {row.localId}
                  </TableCell>
                  <TableCell style={{ width: 90 }} align="center">
                    {row.checkoutId}
                  </TableCell>
                  <TableCell style={{ width: 115 }} align="center">
                    {row.totalTickets}
                  </TableCell>
                  <TableCell style={{ width: 110 }} align="center">
                    <Tooltip
                      title={`
                          200: ${row.st200} ---
                          208: ${row.st208} --- 
                          400: ${row.st400} --- 
                          500: ${row.st500}
                        `}
                      classes={{ tooltip: classes.noMaxWidth }}
                      arrow
                    >
                      <Button className={classes.buttonProgress}>
                        <ProgressBarStacked
                          st200={row.st200}
                          st208={row.st208}
                          st400={row.st400}
                          st500={row.st500}
                          totalTickets={row.totalTickets}
                        />
                      </Button>
                    </Tooltip>
                  </TableCell>
                  <TableCell style={{ width: 105 }} align="center">
                    <div>{dateSeparator(row.startDatetime).hour}</div>
                    <div>{dateSeparator(row.startDatetime).fullDate}</div>
                  </TableCell>
                  <TableCell style={{ width: 100 }} align="center">
                    {row.duration ? getStringTime(row.duration) : "-"}
                  </TableCell>
                  <TableCell style={{ width: 80 }} align="center">
                    {row.attemptsMade}
                  </TableCell>
                  <TableCell style={{ width: 50 }} align="center">
                    <div className="flex-space-evenly">
                      {row.isClosing ? (
                        <Tooltip title={`Ver cierre`} arrow>
                          <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              history.push(
                                `/auditoria/${row.username}/cierre/${row._id}/completados`
                              );
                            }}
                          >
                            <i className="fas fa-cash-register font-size-20px" />
                          </Button>
                        </Tooltip>
                      ) : (
                        <Tooltip title={`Transacciones`} arrow>
                          <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              history.push(
                                `/auditoria/${row.username}/transacciones/${row._id}/completados`
                              );
                            }}
                          >
                            <i className="fas fa-exchange-alt font-size-20px" />
                          </Button>
                        </Tooltip>
                      )}

                      <Tooltip title="CSV" arrow>
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={async () => {
                            setOpenCSV(i);
                            setCSVShow(await axiosGetCSVAudit(row._id));
                          }}
                        >
                          <i className="fas fa-file-csv font-size-20px" />
                        </Button>
                      </Tooltip>
                      <CSVModal
                        csv={CSVShow}
                        filename={row.filename}
                        open={openCSV === i ? true : false}
                        cleanData={setCSVShow}
                        toggler={setOpenCSV}
                      />
                      <Tooltip title="JSON" arrow>
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={async () => {
                            setOpenJSON(i);
                            setJSONShow(await axiosGetJSONAudit(row._id));
                          }}
                        >
                          <i className="fas fa-file-code font-size-20px" />
                        </Button>
                      </Tooltip>
                      <JSONModal
                        open={openJSON === i ? true : false}
                        data={JSONShow}
                        filename={row.filename}
                        cleanData={setJSONShow}
                        toggler={setOpenJSON}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[
                  5,
                  15,
                  30,
                  { label: "Todos", value: totalAudits },
                ]}
                colSpan={0}
                labelRowsPerPage="Registros por pagina"
                count={totalAudits}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}

function TablePaginationActions(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <Tooltip title="Primer página" arrow>
        <span>
          <IconButton
            onClick={handleFirstPageButtonClick}
            disabled={page === 0}
            aria-label="first page"
          >
            {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Página anterior" arrow>
        <span>
          <IconButton
            onClick={handleBackButtonClick}
            disabled={page === 0}
            aria-label="previous page"
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Siguiente página" arrow>
        <span>
          <IconButton
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="next page"
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Última página" arrow>
        <span>
          <IconButton
            onClick={handleLastPageButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="last page"
          >
            {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
          </IconButton>
        </span>
      </Tooltip>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles((theme) => ({
  table: {
    minWidth: 500,
  },
  green: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  yellow: {
    backgroundColor: yellow[500],
    "&:hover": {
      backgroundColor: yellow[700],
    },
  },
  head: {
    backgroundColor: blue[100],
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  buttonProgress: {
    padding: 0,
    minWidth: "100%",
    cursor: "default",
  },
  noMaxWidth: {
    maxWidth: "none",
  },
  paperTitle: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    minHeight: "50px",
    maxHeight: "550px",
    marginBottom: "20px",
  },
}));

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 25,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: grey[300],
  },
  bar: {
    borderRadius: 0,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 50,
      width: "100%",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: "#000",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(0),
    "&:focus": {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  green: {
    color: "#fff",
    backgroundColor: green[500],
  },
  rootTabs: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(0),
  },
  bgColor2e1534: {
    backgroundColor: "#2e1534",
  },
  rootCheck: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(3),
  },
  colorBlack: {
    color: "#000",
  },
  rootBadge: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));
