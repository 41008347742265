
// Constants --------------------------------------------------------
const SESSION_TOKEN = "SESSION_TOKEN";
const DATA_TO_EDIT = "DATA_TO_EDIT";
const CURRENT_USER_DATA = "CURRENT_USER_DATA";
const REDUX_STATE = "REDUX_STATE";
const EXPIRED_SESSION = "EXPIRED_SESSION";
const FILENAME_TRANSACTION = "FILENAME_TRANSACTION";


// Functions --------------------------------------------------------
export const removeSessionToken = ()=>{
    sessionStorage.removeItem(SESSION_TOKEN);
}

export const setSessionToken = (token)=>{
    sessionStorage.setItem(SESSION_TOKEN, token);
}

export const getSessionToken = ()=>{
    return sessionStorage.getItem(SESSION_TOKEN);
}


export const saveDataToEdit = (objData)=>{
    sessionStorage.setItem(DATA_TO_EDIT, JSON.stringify(objData));
}

export const getDataToEdit = ()=>{
    return JSON.parse(sessionStorage.getItem(DATA_TO_EDIT));
}

export const removeDataToEdit = ()=>{
    sessionStorage.removeItem(DATA_TO_EDIT);
}

export const saveCurrentUserData = (currentUserData)=>{
    sessionStorage.setItem(CURRENT_USER_DATA, JSON.stringify(currentUserData) );
}

export const getCurrentUserData = ()=>{
    return JSON.parse( sessionStorage.getItem(CURRENT_USER_DATA) );
}

export const removeCurrentUserData = ()=>{
    sessionStorage.removeItem(CURRENT_USER_DATA);
}

export const saveCopyReduxState = (state)=>{
    try {
        sessionStorage.setItem( REDUX_STATE, JSON.stringify(state) )
    } catch (error) {
        console.error("saveCopyReduxState -> ", error);
    }
}

export const getCopyReduxState = ()=>{
    let copyStore = undefined;

    try {
        copyStore = JSON.parse( sessionStorage.getItem(REDUX_STATE) );
        if ( !copyStore ) { copyStore = undefined }
    } catch (error) {
        console.error("getCopyReduxState -> ", error);
        copyStore = undefined;
    }

    return copyStore;
}

export const removeExpiredSession = ()=>{
    sessionStorage.removeItem(EXPIRED_SESSION);
}

export const setExpiredSession = ()=>{
    sessionStorage.setItem(EXPIRED_SESSION, JSON.stringify(true));
}

export const hasExpiredSession = ()=>{
    return JSON.parse( sessionStorage.getItem(EXPIRED_SESSION) );
}


export const removeFilenameTransaction = ()=>{
    sessionStorage.removeItem(FILENAME_TRANSACTION);
}

export const setFilenameTransaction = (filename)=>{
    sessionStorage.setItem(FILENAME_TRANSACTION, JSON.stringify(filename) );
}

export const getFilenameTransaction = ()=>{
    return JSON.parse( sessionStorage.getItem(FILENAME_TRANSACTION) );
}
