import convertMS from 'ms-convert'; /* https://www.npmjs.com/package/ms-convert */

export const getStringTime = (milliseconds)=>{
    let strignTime = "";
    let aux = convertMS(milliseconds, {});

    if (milliseconds < 1000) { /* Less than a second */
        strignTime = `${milliseconds}ms`;
    } else if (milliseconds < 60000) { /* Less than a minute */
        strignTime = `${aux.seconds}s`
    } else if (milliseconds < 3600000) { /* Less than an hour */
        strignTime = `${aux.minutes}min ${ aux.seconds ? (aux.seconds+'s') : '' }`
    } else {
        strignTime = `
            ${aux.hours}h ${ aux.minutes ? (aux.minutes+'min') : '' } ${ aux.seconds ? (aux.seconds+'s') : '' }
        `
    }

    return strignTime;
}