import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Autocomplete from "../../components/Autocomplete/Autocomplete";
import { Paper } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Label,
  ResponsiveContainer,
  Tooltip as GraphTooltip,
  Legend,
  CartesianGrid,
} from "recharts";
import Title from "../../components/Layouts/MainLayout/Title";
import {
  axiosGetLast30DaysTransactionPerStatus,
  axiosGetCompaniesUsefulLists,
} from "../../helpers/servicesModule";

export default function Chart({ classPaperTitle, fixedHeightPaper }) {
  const { isAdmin, username } = useSelector((state) => state.user.userData);

  const [data, setData] = useState([]);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(""); // Autocomplete
  const [inputValue, setInputValue] = useState(""); // Autocomplete
  const theme = useTheme();

  const [didMount, setDidMount] = useState(false); 

  useEffect(() => {
   setDidMount(true);
   return () => setDidMount(false);
  }, [])

  
  useEffect(() => {
    const auxAsyncFunction = async () => {
      let response = [];
      let usefulLists = {};
      
      
      if (isAdmin) {
        response = await axiosGetLast30DaysTransactionPerStatus();
        usefulLists = await axiosGetCompaniesUsefulLists();
      } else {
        response = await axiosGetLast30DaysTransactionPerStatus(username);
      }
      
      if (!response.error) {
        setData(shortenDataDates(response));
      }
      
      if (!usefulLists.error && usefulLists.labels) {
        usefulLists.labels = ["Todas las empresas", ...usefulLists.labels];
        setOptions(usefulLists);
        setValue("Todas las empresas");
      }
    };
    
    auxAsyncFunction();
  }, []);
  
  useEffect(() => {
    searchHandler(extractCompanyID(inputValue));
  }, [inputValue]);
  
  const extractCompanyID = (option) => {
    let companyID = "";
    
    if (option === "Todas las empresas") {
      companyID = "";
    } else {
      let open = option.lastIndexOf("(");
      let close = option.lastIndexOf(")");
      
      companyID = option.slice(open + 1, close);
    }
    
    return companyID;
  };

  const searchHandler = async (companyID) => {
    setData(await axiosGetLast30DaysTransactionPerStatus(companyID));
  };
  
  const shortenDataDates = (arrayData) => {
    let editedArray = arrayData.map((data) => {
      data.name = data.name.slice(0, 5);
      return data;
    });
    
    return editedArray;
  };
  
  if ( !didMount ) { return null }
  
  return (
    <>
      <Paper className={classPaperTitle}>
        <div className="flex-space-between">
          {isAdmin && (
            <div className="search-container">
              <Autocomplete
                value={value}
                inputValue={inputValue}
                setValue={setValue}
                setInputValue={setInputValue}
                options={options.labels ? options.labels : []}
              />
            </div>
          )}
          <Title>{"Transacciones de los últimos 30 días."}</Title>
        </div>
      </Paper>

      <Paper className={fixedHeightPaper}>
        <ResponsiveContainer>
          <BarChart
            width={600}
            height={900}
            data={data}
            /* margin={{top: 5, right: 30, left: 20, bottom: 5}} */
            margin={{ left: 50, right: 50 }}
            stackOffset="none" /* 'expand' | 'none' | 'wiggle' | 'silhouette' | 'sign' */
          >
            <XAxis dataKey="name" />
            <YAxis>
              <Label
                angle={270}
                position="left"
                style={{
                  textAnchor: "middle",
                  fill: theme.palette.text.primary,
                }}
              >
                Transacciones
              </Label>
            </YAxis>
            <CartesianGrid strokeDasharray="3 2" />
            <GraphTooltip />
            <Legend />
            <Bar
              type="monotone"
              dataKey="completed"
              stroke="#4caf50"
              fill="#4caf50"
              stackId="a"
            />
            <Bar
              type="monotone"
              dataKey="active"
              stroke="#03a9f4"
              fill="#03a9f4"
              stackId="a"
            />
            <Bar
              type="monotone"
              dataKey="waiting"
              stroke="#ffeb3b"
              fill="#ffeb3b"
              stackId="a"
            />
            <Bar
              type="monotone"
              dataKey="failed"
              stroke="#f44336"
              fill="#f44336"
              stackId="a"
            />
            <Bar
              type="monotone"
              dataKey="delayed"
              stroke="#ff9800"
              fill="#ff9800"
              stackId="a"
            />
          </BarChart>
        </ResponsiveContainer>
      </Paper>
    </>
  );
}
