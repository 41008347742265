import React from "react";
import { useSelector } from "react-redux";
import MainLayout from "../../components/Layouts/MainLayout/MainLayout";
import AuditTable from "./AuditTable";
import history from "../../helpers/history";

const Audit = ({ match }) => {
  let ID_COMPANY_AUDIT = "";
  let STATUS = match.params.status;

  const userIsAdmin = useSelector((state) => state.user.userData.isAdmin);
  const companyID = useSelector((state) => state.user.userData.username);

  if (userIsAdmin) {
    ID_COMPANY_AUDIT = match.params.companyID || "";
  } else {
    ID_COMPANY_AUDIT = companyID;
  }

  return (
    <MainLayout title="Auditoría">
      <AuditTable
        ID_COMPANY_AUDIT={ID_COMPANY_AUDIT}
        STATUS={STATUS}
        history={history}
      />
    </MainLayout>
  );
};

export default Audit;
