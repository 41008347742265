import  React from  "react";
import { Route, Redirect } from  "react-router-dom";
import { getSessionToken } from '../../../helpers/sessionStorage';
import { useSelector } from 'react-redux';

const AdminRoute = ({ component, path, exact }) => {

    const token = getSessionToken();
    const isAdmin = useSelector( state => state.user.userData.isAdmin );

    if(!token)
        return <Redirect  to="/ingresar" />
    else if( !isAdmin )
        return <Redirect  to="/dashboard" />
    else
        return <Route  path={path}  exact={exact} component={component} />    
};

export  default AdminRoute;