import {
    SET_USER_ACTIVE
} from '../types';


const initialState = {
    userData: {
        enabled: "",
        isAdmin: "",
        username: "",
        companyName: "",
        sub: ""
    }
}

export default function userReducer(state = initialState, action) {
    switch ( action.type ) {
        case SET_USER_ACTIVE:
            return {
                ...state,
                userData: action.payload
            }
        
        default: 
            return state;
    }
}