import  React from  "react";
import { Route, Redirect } from  "react-router-dom";
import { getSessionToken } from '../../../helpers/sessionStorage';

const  PrivateRoute = ({ component, path, exact }) => {

    const token = getSessionToken();

    return  token ? (
        <Route  path={path}  exact={exact} component={component} />
    ) : ( <Redirect  to="/ingresar" /> );
};


export  default  PrivateRoute;