import React from "react";
import { getSessionToken } from "./helpers/sessionStorage";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import history from "./helpers/history";
import { Provider } from "react-redux";
import AdminRoute from "./components/Routes/AdminRoute/AdminRoute";
import PrivateRoute from "./components/Routes/PrivateRoute/PrivateRoute";
import store from "./redux/store";
import LogIn from "./pages/LogIn/LogIn";
import Dashboard from "./pages/Dashboard/Dashboard";
import Audit from "./pages/Audit/Audit";
import Companies from "./pages/Companies/Companies";
import CreateCompany from "./pages/CreateCompany/CreateCompany";
import EditCompany from "./pages/EditCompany/EditCompany";
import Transactions from "./pages/Audit/Transactions/Transactions";
import Close from "./pages/Audit/Close/Close";

export default function App() {
  return (
    <div className="App">
      <Router history={history}>
        <Provider store={store}>
          <Switch>
            <Route exact path="/ingresar" component={LogIn} />
            <AdminRoute exact path="/empresas" component={Companies} />
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <PrivateRoute exact path="/auditoria/:status" component={Audit} />
            <AdminRoute
              exact
              path="/auditoria/:companyID/:status"
              component={Audit}
            />
            <PrivateRoute
              exact
              path="/auditoria/:companyID/transacciones/:owner/:status"
              component={Transactions}
            />
            <PrivateRoute
              exact
              path="/auditoria/:companyID/cierre/:owner/:status"
              component={Close}
            />
            <AdminRoute
              exact
              path="/empresas/crear-nueva-empresa"
              component={CreateCompany}
            />
            <AdminRoute
              exact
              path="/empresas/editar-empresa"
              component={EditCompany}
            />
            <Route path="/*">
              {getSessionToken() ? (
                <Redirect to="/dashboard" />
              ) : (
                <Redirect to="/ingresar" />
              )}
            </Route>
          </Switch>
        </Provider>
      </Router>
    </div>
  );
}
