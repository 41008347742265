import React from 'react';
import styled from "@emotion/styled";
import PropTypes from "prop-types"


const Success = ({ message }) => {
    return (
        <SuccessParagraph>
            {message}
        </SuccessParagraph>
    );
}

const SuccessParagraph = styled.p`
    text-align: center;
    position: relative;
    padding: .35rem 1.25rem;
    border-radius: .5rem;
    font-weight: 600;
    color: #3a6e3a;
    background-color: #b6e0b6;
    border: 2px solid #62be59;
    margin: 0;
    margin-top: 1px;
    margin-bottom: 9px;
`;

Success.propTypes = {
    message: PropTypes.string.isRequired
}
 
export default Success;