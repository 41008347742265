
const regExpressions = {
    PATTERN_MAIL: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
}



export const isMailFormat = (stringToValidate)=>{
    let answer = false;

    if ( regExpressions.PATTERN_MAIL.test(stringToValidate) ) {
        answer = true;
    }

    return answer;
}