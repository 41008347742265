import React from "react";
import MainLayout from "../../../components/Layouts/MainLayout/MainLayout";
import TransactionsTable from "./TransactionsTable";

const Transactions = ({ match }) => {
  const COMPANY_ID = match.params.companyID;
  const OWNER = match.params.owner;
  let STATUS = match.params.status;

  return (
    <MainLayout title="Auditoría > Transacciones">
      <TransactionsTable
        COMPANY_ID={COMPANY_ID}
        OWNER={OWNER}
        STATUS={STATUS}
      />
    </MainLayout>
  );
};

export default Transactions;
