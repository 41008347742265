import React from 'react'
import JSONPretty from 'react-json-pretty';
import {
    Modal,
    Backdrop,
    Fade,
    Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DownloadButton from './DownloadButton';
import CopyButton from './CopyButton';
const stringifyObject = require('stringify-object');


const GenericModal = ({ filename, complementaryName, message, open, toggler }) => {

    const classes = useStyles(); 

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={ ()=>{ toggler(false) }}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
        >
            
            <Fade in={open}>
                <div className={classes.paper}>
                <div className="display-flex">
                    <h2 id="transition-modal-title">
                        <i className="fas fa-exclamation-circle type-icon" /> 
                        {" Mensaje"}
                    </h2>
                <div className="max-height-30px pt-17px">
                    <DownloadButton filename={filename} complementaryName={complementaryName} data={message} fileType={'txt'} />
                    <CopyButton data={stringifyObject(JSON.parse(message), { indent: '    ', singleQuotes: false })} />
                    <Button
                        size="small" 
                        variant="contained"
                        color="inherit" 
                        className={classes.marginLeft10px}
                        onClick={ ()=>{ toggler(false) } } 
                    >Cerrar</Button >
                </div>
                </div>
                <div className="code-modal" >
                    <JSONPretty id="json-pretty" data={message} />
                </div>
                </div>
            </Fade>
        </Modal>
    );
}
 
export default GenericModal;


const useStyles = makeStyles((theme)=>({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    marginLeft10px: {
        marginLeft: '10px'
    }
}));


