import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setUserActiveAction } from "../../redux/actions/userActions";
import { makeStyles } from "@material-ui/core/styles";
import {
  CssBaseline,
  TextField,
  Typography,
  Container,
  Button,
  CircularProgress,
  Avatar,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import history from "../../helpers/history";
import { axiosEditCompany } from "../../helpers/servicesModule";
import { isMailFormat } from "../../helpers/validationFunctions";
import Error from "../../components/MessageBoxes/Error/Error";
import Success from "../../components/MessageBoxes/Success/Success";
import { getDataToEdit, removeDataToEdit } from "../../helpers/sessionStorage";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(0, 0, 0),
  },
  green: {
    backgroundColor: green[500],
  },
  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
}));

const FormEditCompany = () => {
  const classes = useStyles();

  const initialState = {
    username: "",
    email: "",
    companyName: "",
  };

  const errorState = {
    state: false,
    message: "",
  };
  const successState = {
    state: false,
    message: "",
  };

  const [companyToEdit, updateCompanyToEdit] = useState(initialState);
  useEffect(() => {
    updateCompanyToEdit(getDataToEdit());
  }, []);
  const { companyName, email } = companyToEdit;

  const [blurOnCompanyField, updateBlurOnCompanyField] = useState(false);
  const [companyError, updateCompanyError] = useState(errorState);

  const [emailError, updateEmailError] = useState(errorState);
  const [focusOnEmailField, updateFocusOnEmailField] = useState(false);
  const [blurOnEmailField, updateBlurOnEmailField] = useState(false);

  const [loading, updateLoading] = useState(false);
  const [error, updateError] = useState(errorState);
  const [success, updateSuccess] = useState(successState);

  const dispatch = useDispatch();

  const CURRENT_COMPANY = useSelector((state) => state.user.userData);

  useEffect(() => {
    if (companyToEdit.companyName.trim().length > 0) {
      updateCompanyError({
        state: false,
        message: "",
      });
    }

    if (focusOnEmailField) {
      setTimeout(() => {
        if (isMailFormat(companyToEdit.email)) {
          updateEmailError({
            state: false,
            message: "",
          });
        } else {
          updateEmailError({
            state: true,
            message: "Formato inválido.",
          });
        }
      }, 700);
    }
  }, [companyToEdit]);

  useEffect(() => {
    if (blurOnCompanyField && !companyToEdit.companyName) {
      updateCompanyError({
        state: true,
        message: "Campo vacío.",
      });
    }

    if (blurOnEmailField && !companyToEdit.email) {
      updateEmailError({
        state: true,
        message: "Campo vacío.",
      });
    }
  }, [blurOnCompanyField, blurOnEmailField]);

  const changeHandler = (event) => {
    updateCompanyToEdit({
      ...companyToEdit,
      [event.target.name]: event.target.value,
    });
  };

  const focusOnEmailFieldHandler = () => {
    updateFocusOnEmailField(true);
  };

  const blurHandler = (event) => {
    cleanSubmitError();

    switch (event.target.name) {
      case "email":
        updateBlurOnEmailField(true);
        break;
      case "companyName":
        updateBlurOnCompanyField(true);
        break;
    }
  };

  const isSubmitDisabled = () => {
    let response = true;
    const dataToEdit = getDataToEdit();
    const isSameData =
      companyToEdit.companyName === dataToEdit.companyName &&
      companyToEdit.email === dataToEdit.email;

    if (!isSameData) {
      if (!(companyError.state || emailError.state)) {
        if (companyToEdit.companyName && companyToEdit.email) {
          response = false;
        }
      }
    }

    return response;
  };

  const cancelHandler = () => {
    removeDataToEdit();
  };

  const submitHandler = (event) => {
    event.preventDefault();
    cleanSubmitError();
    updateLoading(true);

    // The data is regrouped...
    const editedUser = {
      username: getDataToEdit().username,
      companyName: companyToEdit.companyName,
      email: companyToEdit.email,
    };

    const isCurrentCompany = editedUser.username === CURRENT_COMPANY.username;

    setTimeout(async () => {
      let response = await axiosEditCompany(history, editedUser);
      updateLoading(false);

      if (response && response.error) {
        updateError({
          state: true,
          message: response.error.message,
        });
      } else if (response.success) {
        updateSuccess({
          state: true,
          message: response.success.message,
        });

        removeDataToEdit();

        if (isCurrentCompany) {
          dispatch(
            setUserActiveAction({
              ...CURRENT_COMPANY,
              companyName,
              email,
            })
          );
        }

        setTimeout(() => {
          history.push(`/empresas`);
        }, 3000);
      }
    }, 2000);
  };

  const cleanSubmitError = () => {
    updateError({ state: false, message: "" });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={success.state ? classes.green : ""}>
          <i className="fas fa-university" />
        </Avatar>
        <Typography component="h1" variant="h5">
          Editar Empresa
        </Typography>
        <form className={classes.form}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="companyName"
            label="Nombre de la empresa"
            helperText={companyError.message}
            error={companyError.state}
            value={companyToEdit.companyName}
            type="text"
            id="companyName"
            autoFocus
            autoComplete="current-password"
            onBlur={blurHandler}
            onChange={changeHandler}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="email"
            label="E-mail"
            helperText={emailError.message}
            error={emailError.state}
            value={companyToEdit.email}
            type="email"
            id="email"
            onFocus={focusOnEmailFieldHandler}
            onBlur={blurHandler}
            onChange={changeHandler}
          />

          {success.state ? (
            <Success message={success.message} />
          ) : (
            <>
              {error.state && <Error message={error.message} />}
              <div className="form-buttons">
                {loading ? (
                  <Button
                    variant="contained"
                    color="inherit"
                    fullWidth
                    disabled={loading}
                    onClick={cancelHandler}
                  >
                    Cancelar
                  </Button>
                ) : (
                  <Link to="/empresas" className="plain-text full-width">
                    <Button
                      variant="contained"
                      color="inherit"
                      fullWidth
                      disabled={loading}
                      onClick={cancelHandler}
                    >
                      Cancelar
                    </Button>
                  </Link>
                )}

                <div className="ml-20px full-width">
                  <Button
                    disabled={loading || isSubmitDisabled()}
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    onBlur={blurHandler}
                    // TODO no anda evento onBlur del boton, no limpia error
                    className={classes.submit}
                    onClick={submitHandler}
                  >
                    {loading ? (
                      <div className={classes.root}>
                        <CircularProgress size={24} />
                      </div>
                    ) : (
                      "Guardar"
                    )}
                  </Button>
                </div>
              </div>
            </>
          )}
        </form>
      </div>
    </Container>
  );
};

export default FormEditCompany;
