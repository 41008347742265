import { axiosAPIForm, axiosAPIJSON } from '../config/axios';
import queryString from 'querystring';


export const axiosTryLogIn = async (history, userData)=>{
    let response = "";
    try {
        response = await axiosAPIJSON.post('/auth/login', userData);
        response = response.data.access_token;
    } catch (error) {
        if ( error.response ) {
            if ( error.response.data) {
                switch (error.response.data.statusCode) {
                    case 401: 
                        response = { error: { message: error.response.data.message} }
                        break;
                    case 500: 
                        response = { error: { message: 'Error interno en el servidor.'} }
                        break;
                }
            }
        } else { response = { error: { message: 'Error de conexión'} } }
    }

    return response;
}


export const axiosGetAllCompanies = async (companyID)=>{
    let response = "";
    let auxRoute = companyID ? `/${companyID}` : '/findAll';

    try {
        response = await axiosAPIJSON.get(`/users${auxRoute}`);
        if ( response?.data ) {
            response = companyID ? [response.data] : response.data;
        }
    } catch (error) {
        response = {error: true}
    }

    return response;
}


export const axiosGetCompaniesUsefulLists = async ()=>{
    let response = await axiosGetAllCompanies();
    let auxResponse = {};

    if ( response && !response.error ) {
        auxResponse.data = response.map( objUser => (
            {companyID: objUser.username, companyName: objUser.companyName}
        )).sort((a, b) => (a.companyName < b.companyName ? -1 : 1));
        auxResponse.usernames = response.map( objUser => objUser.username).sort((a, b) => (a < b ? -1 : 1));
        auxResponse.labels = auxResponse.data.map( obj => `${obj.companyName} (${obj.companyID})` ).sort((a, b) => (a < b ? -1 : 1));
    }
    
    return auxResponse;
}

export const axiosCreateCompany = async (history, newUser)=>{
    let response = "";

    try {
        response = await axiosAPIForm.post('/users/create',
            queryString.stringify(newUser)
        );
        
        if ( response.data.username ) {
            response = { success: { message: "Empresa registrada con exito." } }
        }
    } catch (error) {
        if ( error.response ) {
            response = { 
                error: { 
                    message: "Error al intentar registrar. Intente nuevamente"
                }
            }
        }
    }
    return response;
}

export const axiosEditCompany = async (history, editedCompany)=>{
    let response = "";

    try {
        response = await axiosAPIForm.put(
            `/users/${editedCompany.username}`, queryString.stringify(editedCompany)
        );

        if ( response.data.username) {
            response = { success: { message: "Empresa actualizada con exito." } }
        }
    } catch (error) {
        response = { 
            error: { 
                message: "Error al intentar editar. Intente nuevamente.", 
                statusCode: error.response ? error.response.status : ""
            }
        };
    }

    return response;
}

export const axiosRegeneratePassword = async (history, username)=>{
    let response = "";

    try {
        response = await axiosAPIJSON.put(
            `/users/refreshPassword/${username}`, {}
        )
    } catch (error) {
        response = { error: error.response }
    }

    return response;
}

export const axiosAuditFind = async (companyID, state, seeClosings, seeMovements,  page, rowsPerPage)=>{    
    let response = "";


    let data = JSON.stringify({
        username: companyID,
        page: page,
        limit: rowsPerPage,
        closings: seeClosings,
        movements: seeMovements,
        state: state
    });

    try {
        response = await axiosAPIJSON.post('/audit/find', data);
        response = response.data;
    } catch (error) {
        response = { error: error.response }
    }

    return response;
}

export const axiosGetJSONAudit = async (idAudit)=>{
    let response = "";

    try {
        response = await axiosAPIJSON.get(`/audit/getJSON/${idAudit}`)
        response = response.data;
    } catch (error) {
        response = { error: true }
    }
    
    return response;
}

export const axiosGetCSVAudit = async (idAudit)=>{
    let response = "";

    try {
        response = await axiosAPIJSON.get(`/audit/getCSV/${idAudit}`)
        response = response.data;
    } catch (error) {
        response = { error: true }
    }
    
    return response;
}

export const axiosTransactionFind = async (owner, state, page, rowsPerPage)=>{    
    let response = "";
    
    let data = JSON.stringify({
        owner: owner,
        page: page,
        limit: rowsPerPage,
        state: state
    });

    try {
        response = await axiosAPIJSON.post('/transactionAudit/find', data);
        response = response.data;
    } catch (error) {
        response = { error: error.response }
    }

    return response;
}

export const axiosGetAuditFilename = async (idAudit)=>{
    let response = '';

    try {
        response = await axiosAPIJSON(`/audit/getById/${idAudit}`);
        response = response.data.filename;
    } catch (error) {
        response = {error: true};
    }

    return response;
}

export const axiosGetJSONTransaction = async (idTransaction)=>{
    let response = "";

    /* http://localhost:3001/transactionAudit/getJSON/600dbd95a8bf3576cfaa972b */

    try {
        response = await axiosAPIJSON.get(`/transactionAudit/getJSON/${idTransaction}`)
        response = response.data;
    } catch (error) {
        response = { error: error.response }
    }

    return response;
}

export const axiosGetAuditsCountByStatus = async (companyID, closings, movements)=>{
    let response = "";

    let data = JSON.stringify({
        username: companyID,
        closings: closings,
        movements: movements
    });

    try {
        response = await axiosAPIJSON.post(`/audit/getAuditsCountByStatus`, data)
    } catch (error) {
        response = { error: error.response ? error.response : {} }
    }

    return response;
}

export const axiosGetTransactionsCountByStatus = async (ownerID)=>{
    let response = "";

    try {
        response = await axiosAPIJSON(`/transactionAudit/getAuditsCountByStatus/${ownerID}`)
        response = response.data

    } catch (error) {
        response = { error: error.response ? error.response : {} }
    }

    return response;
}

export const axiosGetLast30DaysTransactionPerStatus = async (username)=>{
    let response = "";
    let auxRoute = username ? `/${username}` : ""

    try {
        response = await axiosAPIJSON(`/transactionAudit/Last30DaysTransactionPerStatus${auxRoute}`);
        response = response.data;
    } catch (error) {
        response = { error: error.response ? error.response : {} }
    }

    return response;
}

