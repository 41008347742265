import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Tooltip, Button } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';





const CopyButton = ({ data }) => {

    const classes = useStyles(); 
    const [copied, setCopied] = useState(false); 

    return (
        <Tooltip 
            title={copied ? '¡Copiado!' : 'Copiar' } 
            className={copied ? classes.green : ''} >
            <CopyToClipboard text={data} > 
                <Button
                    size="large" 
                    variant="contained"
                    color="primary"
                    onClick={ ()=>{
                        setCopied(true);
                        setTimeout( ()=>{ setCopied(false) }, 3000 );
                    }} 
                ><i className="fas fa-copy" /></Button>
            </CopyToClipboard> 
        </Tooltip>
    );
}
 
export default CopyButton;

const useStyles = makeStyles((theme)=>({
    green: {
        backgroundColor: green[700],
        '&:hover': {
            backgroundColor: green[800],
        }
    }
}));