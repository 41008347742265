import React from 'react';



const ProgressBarStacked = ({ st200, st208, st400, st500, totalTickets }) => {

    const percentage200 = (st200 / totalTickets) * 100;
    const percentage208 = (st208 / totalTickets) * 100;
    const percentage400 = (st400 / totalTickets) * 100;
    const percentage500 = (st500 / totalTickets) * 100;

    return (
        <div style={{height: '20px', width: '100%', backgroundColor: '#eee', display: 'flex'/* , borderRadius: '6px 6px 6px 6px' */}}>
            <div style={{width: `${percentage200}%`, height: '20px', backgroundColor: '#4caf60'/* , borderRadius: '6px 0 0 6px' */}} />
            <div style={{width: `${percentage208}%`, height: '20px', backgroundColor: '#ffeb6b'}} />
            <div style={{width: `${percentage400}%`, height: '20px', backgroundColor: '#ff9800' }} />
            <div style={{width: `${percentage500}%`, height: '20px', backgroundColor: '#f44666'}} />
        </div>
    )
}
 
export default ProgressBarStacked;