import { createStore, compose } from 'redux';
import reducer from './reducers/reducersIndex';
import { saveCopyReduxState, getCopyReduxState } from '../helpers/sessionStorage';

const store = createStore(
    reducer,
    getCopyReduxState(),
    compose( 
        typeof window === 'object' && (
            typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined' ? (
                window.__REDUX_DEVTOOLS_EXTENSION__() 
            ) : (
                f => f
            )
        )
    )
)

store.subscribe( ()=>{ saveCopyReduxState(store.getState() ) } );

export default store;