import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { green, blue } from "@material-ui/core/colors";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  Paper,
  IconButton,
  Button,
  Tooltip,
} from "@material-ui/core";
import Autocomplete from "../../components/Autocomplete/Autocomplete";
import {
  FirstPage as FirstPageIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage as LastPageIcon,
} from "@material-ui/icons";
import SweetAlert from "sweetalert2"; /* https://www.npmjs.com/package/sweetalert2 */
import history from "../../helpers/history";
import { saveDataToEdit } from "../../helpers/sessionStorage";
import {
  axiosGetAllCompanies,
  axiosEditCompany,
  axiosRegeneratePassword,
  axiosGetCompaniesUsefulLists,
} from "../../helpers/servicesModule";

export default function CompanyTable() {
  const classes = useStyles2();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [arrayUsers, updateArrayUsers] = useState([]);
  const [render, runRendering] = useState(false);

  const [options, setOptions] = useState([]); // Autocomplete
  const [value, setValue] = useState(""); // Autocomplete
  const [inputValue, setInputValue] = useState(""); // Autocomplete

  const [didMount, setDidMount] = useState(false); 


  useEffect(() => {
   setDidMount(true);
   return () => setDidMount(false);
  }, [])


  useEffect(() => {
    const auxAsyncFunction = async () => {
      let usefulLists = await axiosGetCompaniesUsefulLists();

      if ( !usefulLists.error && usefulLists.labels ) {
        usefulLists.labels = ["Todas las empresas", ...usefulLists.labels];
        setOptions(usefulLists);
        setValue("Todas las empresas");
      }
    };

    auxAsyncFunction();
  }, []);

  useEffect(() => {
    const auxAsyncFunction = async () => {
      let response = await axiosGetAllCompanies();

      if (!response.error) {
        updateArrayUsers(response);
      }
    };

    auxAsyncFunction();
  }, [render]);

  useEffect(() => {
    const auxAsyncFunction = async () => {
      if (inputValue !== "" && inputValue !== "Todas las empresas") {
        if (page !== 0) {
          setPage(0);
        }

        let auxIdCompany = extractCompanyID(inputValue);
        if (
          options.labels &&
          options.usernames.some((stringID) => stringID === auxIdCompany)
        ) {
          const response = await axiosGetAllCompanies(auxIdCompany);

          if (!response.error) {
            updateArrayUsers(response);
          }
        }
      } else {
        const response = await axiosGetAllCompanies();

        if (!response.error) {
          updateArrayUsers(response);
        }
      }
    };

    auxAsyncFunction();
  }, [inputValue]);

  const extractCompanyID = (option) => {
    let companyID = "";

    if (option === "Todas las empresas" || option === "") {
      companyID = "";
    } else if (option) {
      let opening = option.lastIndexOf("(");
      let closing = option.lastIndexOf(")");
      if (opening > -1 && closing > -1) {
        companyID = option.slice(opening + 1, closing);
      }
    }

    return companyID;
  };

  const columns = [
    { id: "companyName", label: "Empresa", minWidth: 100 },
    { id: "username", label: "Código", minWidth: 50, align: "center" },
    { id: "permissions", label: "Permisos", minWidth: 50, align: "center" },
    { id: "state", label: "Estado", minWidth: 50, align: "center" },
    { id: "email", label: "E-mail", minWidth: 100, align: "center" },
    { id: "actions", label: "Acciones", minWidth: 100, align: "center" },
  ];

  const rows = [...arrayUsers].sort((a, b) =>
    a.companyName < b.companyName ? -1 : 1
  );

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const seeAuditHandler = (username) => {
    history.push(`/auditoria/${username}/completados`);
  };

  const editClickHandler = (username, companyName, email) => {
    const dataToEdit = { username, companyName, email };
    saveDataToEdit(dataToEdit);

    history.push("/empresas/editar-empresa");
  };

  const regeneratePasswordHandler = (companyName, username) => {
    SweetAlert.fire({
      title: "Confirmar",
      text: `¿Desea regenerar la contraseña de la empresa ${companyName}?\n(Código de empresa: ${username})`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Regenerar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.value) {
        let response = await axiosRegeneratePassword(history, username);

        if (response && !response.error) {
          SweetAlert.fire(
            "Confirmado",
            `Se ha regenerado la contraseña de la empresa ${companyName}`,
            "success"
          );
        }
      } else if (result.dismiss === SweetAlert.DismissReason.cancel) {
        SweetAlert.fire(
          "Cancelado",
          "No se ha regenerado la contraseña.",
          "error"
        );
      }
    });
  };

  const disableCompanyHandler = (companyName, username) => {
    SweetAlert.fire({
      title: "Confirmar",
      text: `¿Desea deshabilitar la empresa ${companyName}?\n\n(Código de empresa: ${username})`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Deshabilitar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.value) {
        let response = await axiosEditCompany(history, {
          username,
          enabled: false,
        });

        if (response && response.error) {
          SweetAlert.fire(
            "Error",
            `No se pudo deshabilitar. Intente nuevamente.`,
            "error"
          );
        } else if (response.success) {
          runRendering(!render);

          SweetAlert.fire(
            "Confirmado",
            `Se ha deshabilitado la empresa ${companyName}`,
            "success"
          );
        }
      } else if (result.dismiss === SweetAlert.DismissReason.cancel) {
        SweetAlert.fire(
          "Cancelado",
          "No se ha deshabilitado la empresa.",
          "error"
        );
      }
    });
  };

  const enableCompanyHandler = (companyName, username) => {
    SweetAlert.fire({
      title: "Confirmar",
      text: `¿Desea habilitar la empresa ${companyName}?\n(Código de empresa: ${username})`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Habilitar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.value) {
        let response = await axiosEditCompany(history, {
          username,
          enabled: true,
        });

        if (response.error) {
          SweetAlert.fire(
            "Error",
            `No se pudo habilitar. Intente nuevamente.`,
            "error"
          );
        } else if (response.success) {
          runRendering(!render);

          SweetAlert.fire(
            "Confirmado",
            `Se ha habilitado la empresa ${companyName}`,
            "success"
          );
        }
      } else if (result.dismiss === SweetAlert.DismissReason.cancel) {
        SweetAlert.fire(
          "Cancelado",
          "No se ha habilitado la empresa.",
          "error"
        );
      }
    });
  };

  return (
    <>
      <Paper className={classes.paperTitle}>
        <div className="flex-space-between">
          <div className="search-container">
            <Autocomplete
              value={value}
              inputValue={inputValue}
              setValue={setValue}
              setInputValue={setInputValue}
              options={options.labels ? options.labels : []}
            />
          </div>
          <div className="flex-align-items-center">
            <Link
              to={{
                pathname: `/empresas/crear-nueva-empresa`,
              }}
              className="plain-text"
            >
              <Tooltip title="Crear empresa" arrow>
                <Button variant="contained" size="small" color="primary">
                  <i className="fas fa-plus" />
                </Button>
              </Tooltip>
            </Link>
          </div>
        </div>
      </Paper>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="custom pagination table">
          <TableHead className={classes.head}>
            <TableRow>
              {columns.map((column, i) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <strong>{column.label}</strong>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row) => (
              <TableRow key={row._id}>
                <TableCell component="th" scope="row">
                  {row.companyName}
                </TableCell>
                <TableCell style={{ width: 170 }} align="center">
                  {row.username}
                </TableCell>
                <TableCell style={{ width: 170 }} align="center">
                  {row.isAdmin ? "Administrador" : "Usuario"}
                </TableCell>
                <TableCell style={{ width: 170 }} align="center">
                  {row.enabled ? "Habilitado" : "Deshabilitado"}
                </TableCell>
                <TableCell style={{ width: 240 }} align="center">
                  {row.email}
                </TableCell>
                <TableCell style={{ width: 162 }} align="justify">
                  <div>
                    <div className="flex-space-between mb-3px">
                      <Tooltip title={`Ver auditoría`} arrow>
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            seeAuditHandler(row.username);
                          }}
                        >
                          <i className="fas fa-search" />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Editar empresa" arrow>
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            editClickHandler(
                              row.username,
                              row.companyName,
                              row.email
                            );
                          }}
                        >
                          <i className="fas fa-pen" />
                        </Button>
                      </Tooltip>
                    </div>
                    <div className="flex-space-between">
                      <Tooltip title={`Regenerar contraseña`} arrow>
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            regeneratePasswordHandler(
                              row.companyName,
                              row.username
                            );
                          }}
                        >
                          <i className="fas fa-key"></i>
                        </Button>
                      </Tooltip>

                      {row.enabled ? (
                        <Tooltip title="Deshabilitar" arrow>
                          <Button
                            size="small"
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                              disableCompanyHandler(
                                row.companyName,
                                row.username
                              );
                            }}
                          >
                            <i className="fas fa-ban" />
                          </Button>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Habilitar" arrow>
                          <Button
                            size="small"
                            variant="contained"
                            className={classes.green}
                            onClick={() => {
                              enableCompanyHandler(
                                row.companyName,
                                row.username
                              );
                            }}
                          >
                            <i className="fas fa-check color-white" />
                          </Button>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[
                  5,
                  15,
                  30,
                  { label: "Todos", value: rows.length },
                ]}
                colSpan={6}
                labelRowsPerPage="Registros por pagina"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  green: {
    color: "#fff",
    backgroundColor: green[500],
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <Tooltip title="Primer página" arrow>
        <span>
          <IconButton
            onClick={handleFirstPageButtonClick}
            disabled={page === 0}
            aria-label="first page"
          >
            {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Página anterior" arrow>
        <span>
          <IconButton
            onClick={handleBackButtonClick}
            disabled={page === 0}
            aria-label="previous page"
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Siguiente página" arrow>
        <span>
          <IconButton
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="next page"
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Última página" arrow>
        <span>
          <IconButton
            onClick={handleLastPageButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="last page"
          >
            {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
          </IconButton>
        </span>
      </Tooltip>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles((theme) => ({
  table: {
    minWidth: 500,
  },
  head: {
    backgroundColor: blue[100],
  },
  green: {
    backgroundColor: green[600],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  paperTitle: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    minHeight: "50px",
    maxHeight: "550px",
    marginBottom: "20px",
  },
}));
