import React from 'react';
import { useSelector } from 'react-redux';
import {
    Button,
    Tooltip
} from '@material-ui/core';
import history from '../../helpers/history';


const LogOut = () => {

    const USER_DATA = useSelector( state => state.user.userData );

    const logOutHandler = ()=>{

        history.push('/ingresar');

    }
    
    return (
        <span>
            <strong className="font-size-17px">
                <i className="fas fa-university pr-5px" />
                {USER_DATA.companyName}
            </strong>
            {
                ` (ID: ${USER_DATA.username})`
            }
            <span className="color-black pl-7px">
                <Tooltip title="Cerrar sesión" arrow  >
                    <Button variant="contained" color="inherit" size="small" onClick={ logOutHandler }>
                        <i className="fas fa-sign-out-alt font-size-18px" />
                    </Button>
                </Tooltip>
            </span>
        </span>
    );
}
 
export default LogOut;