import {
    SET_USER_ACTIVE
} from '../types';



export const setUserActiveAction = (userData) => ({
    type: SET_USER_ACTIVE,
    payload: userData
})

export const setEmptyStoreAction = () => ({
    type: SET_USER_ACTIVE,
    payload: {}
})