/**
 * Converts a date (UTC) to an object with multiple useful data, in UTC-3.
 * This function depends on toLocaleStringEsAR().
 * @param {string} date Date in ISO8601 format..
 * @param {boolean} separatorBar Optional. In case of being `true`, the symbol that separates the date will be a slash, if not, it will be a hyphen.
 */
export const dateSeparator = (date, separatorBar)=>{
    let argentineTime = toLocaleStringEsAr(date);
    argentineTime = dateNormalizer(argentineTime);
    let symbol = "-";

    if ( separatorBar ) { symbol = '/' }

    return { 
        hour: argentineTime.slice(11, 16),
        day: argentineTime.slice(0, 2),
        monthNumber: argentineTime.slice(3, 5),
        monthName: getMonthName(argentineTime.slice(3, 5)), 
        dayAndMonth: argentineTime.slice(0, 2) +symbol+ argentineTime.slice(3, 5),
        date:  argentineTime.slice(0, 2) +symbol+ argentineTime.slice(3, 5) +symbol+ argentineTime.slice(8, 10),
        fullDate: argentineTime.slice(0, 2) +symbol+ argentineTime.slice(3, 5) +symbol+ argentineTime.slice(6, 10)
    };
}

/**
 * It is possible that the date delivered by `Date().toLocaleString()` has the following format: 'd/m/yyyy hh:mm:ss'. This function fills the days and months with leading zeros to get to the format 'dd/mm/yyyy hh:mm:ss' and it returns the new string.
 * @param {string} stringDate Date obtained from `Date().toLocaleString()`.
 */
const dateNormalizer = (stringDate)=>{
    let arrayData = stringDate.split('/');

    if ( arrayData[0] < 10 ) { arrayData[0] = '0'+arrayData[0].toString() }
    if ( arrayData[1] < 10 ) { arrayData[1] = '0'+arrayData[1].toString() }

    return arrayData[0]+'/'+arrayData[1]+'/'+arrayData[2];
}

/**
 * Converts a number from 1 to 12, corresponding to a month of the year and returns a string with the name of that month in Spanish.
 * @param {number} monthNumber Month number (1, ..., 12).
 */
const getMonthName = (monthNumber)=>{
    let response = null;

    switch (monthNumber) {
        case '01': response = 'Enero'; break;
        case '02': response = 'Febrero'; break;
        case '03': response = 'Marzo'; break;
        case '04': response = 'Abril'; break;
        case '05': response = 'Mayo'; break;
        case '06': response = 'Junio'; break;
        case '07': response = 'Julio'; break;
        case '08': response = 'Agosto'; break;
        case '09': response = 'Septiembre'; break;
        case '10': response = 'Octubre'; break;
        case '11': response = 'Noviembre'; break;
        case '12': response = 'Diciembre'; break;
        default: response = 'Número incorrecto.'
    }

    return response;
}

/**
 * Converts a date and time in UTC format to Argentina time (UTC-3)
 * @param {string} stringDate Date in ISO8601 fotmat.
 */
const toLocaleStringEsAr = (stringDate)=>{
    return new Date(stringDate).toLocaleString('es-AR');
}