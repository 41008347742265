import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { green, grey, blue, yellow, orange } from "@material-ui/core/colors";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  Paper,
  IconButton,
  Button,
  Tooltip,
  Typography,
  LinearProgress,
  Tabs,
  Tab,
} from "@material-ui/core";
import {
  FirstPage as FirstPageIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage as LastPageIcon,
} from "@material-ui/icons";

import { dateSeparator } from "../../../helpers/dates";
import Title from "../../../components/Layouts/MainLayout/Title";
import {
  axiosTransactionFind,
  axiosGetJSONTransaction,
  axiosGetTransactionsCountByStatus,
  axiosGetAuditFilename,
  axiosGetCompaniesUsefulLists,
} from "../../../helpers/servicesModule";
import { getStringTime } from "../../../helpers/times";
import GenericModal from "../../../components/Modals/GenericModal";
import JSONModal from "../../../components/Modals/JSONModal";
import {
  stateIndexToText,
  stateTextToIndex,
  translateStateToEnglish,
  translateStateToSpanish,
} from "../../../helpers/statusTabsTools";

export default function TransactionsTable({ COMPANY_ID, OWNER, STATUS }) {
  const classes = useStyles2();

  const [companyName, setCompanyName] = useState("");

  const [transactionsData, updateTransactionsData] = useState({
    owner: OWNER,
    transactions: [],
  });
  const { owner, transactions } = transactionsData;

  const [CSVFilename, setCSVFilename] = useState("Cargando...");

  const [JSONShow, setJSONShow] = useState(null);
  const [openJSON, setOpenJSON] = useState(false);
  const [openApiErrorResponse, setOpenApiErrorResponse] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalTransactions, updateTotalTransactions] = useState(0);

  const auxValueTab = STATUS
    ? stateTextToIndex(translateStateToEnglish(STATUS))
    : 0;
  const [valueTab, setValueTab] = useState(auxValueTab);
  const [transactionsCountByStatus, updateTransactionsCountByStatus] = useState('');

  const [didMount, setDidMount] = useState(false); 

  useEffect(() => {
   setDidMount(true);
   return () => setDidMount(false);
  }, [])


  useEffect(() => {
    const auxAsyncFunction = async () => {
      setCSVFilename(await axiosGetAuditFilename(OWNER));
    };

    auxAsyncFunction();
  }, []);

  useEffect(() => {
    const auxAsyncFunction = async () => {
      let response = await axiosGetCompaniesUsefulLists();

      if (!response.error && response.data) {
        let auxCompanyName = response.data.filter(
          (obj) => obj.companyID === COMPANY_ID
        )[0];
        if (auxCompanyName) {
          setCompanyName(auxCompanyName.companyName);
        }
      }
    };

    auxAsyncFunction();
  }, []);

  useEffect(() => {
    const auxAsyncFunction = async () => {
      getCounters(owner);
    };

    auxAsyncFunction();
  }, [valueTab, rowsPerPage, page]);

  useEffect(() => {
    const auxAsyncFunction = async () => {
      getTransactions(owner);
    };

    auxAsyncFunction();
  }, [valueTab, page, rowsPerPage]);

  useEffect(() => {
    window.history.replaceState(
      null,
      null,
      `/auditoria/${COMPANY_ID}/transacciones/${owner}/${translateStateToSpanish(
        stateIndexToText(valueTab)
      )}`
    );
  }, [valueTab]);

  const getCounters = async (owner)=>{
    let response = await axiosGetTransactionsCountByStatus(owner);
      let states = {};

    if (!response.error) {
      response.map((objDataState) => {
        states[objDataState.status] = objDataState.count;
      });

      updateTransactionsCountByStatus(states);
    }
  }

  const getTransactions = async (owner) => {
    let response = await axiosTransactionFind(
      owner,
      stateIndexToText(valueTab),
      page,
      rowsPerPage
    );

    if (response) {
      if (!response.error) {
        updateTransactionsData({
          ...transactionsData,
          transactions: response.audits,
        });
        updateTotalTransactions(response.total);
      }
    }
  };

  const handleChangeTab = (event, newValueTab) => {
    setValueTab(newValueTab);
    setPage(0);
  };

  const columns = [
    { id: "idColumna1", label: "JobID", minWidth: 25, align: "center" },
    { id: "idColumna7", label: "Fecha inicio", minWidth: 50, align: "center" },
    { id: "idColumna8", label: "Duración", minWidth: 50, align: "center" },
    { id: "idColumna9", label: "Reintentos", minWidth: 50, align: "center" },
    { id: "idColumna10", label: "N° ticket", minWidth: 50, align: "center" },
    { id: "idColumna11", label: "Estado", minWidth: 50, align: "center" },
    { id: "idColumna12", label: "Acciones", minWidth: 50, align: "center" },
  ];

  const rows = [...transactions];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getStatusButton = (filename, complementaryName, state, message, i) => {
    /* http://confluence.scanntech.com/pages/viewpage.action?pageId=122589143 */
    let response = "";

    switch (state) {
      case "200":
        response = (
          <Tooltip title="Operación realizada con éxito" arrow>
            <Button
              size="small"
              variant="contained"
              className={`${classes.green} pointer-none`}
            >
              <strong>{state}</strong>
            </Button>
          </Tooltip>
        );
        break;
      case "208":
        response = (
          <Tooltip title="La operación ya fue procesada anteriormente" arrow>
            <Button
              size="small"
              variant="contained"
              className={`${classes.yellow} pointer-none`}
            >
              <strong>{state}</strong>
            </Button>
          </Tooltip>
        );
        break;
      case "400":
        response = (
          <>
            <Tooltip title="Los datos suministrados son incorrectos" arrow>
              <Button
                size="small"
                variant="contained"
                className={classes.orange}
                onClick={async () => {
                  if (message) {
                    setOpenApiErrorResponse(i);
                  }
                }}
              >
                <strong>{state}</strong>
              </Button>
            </Tooltip>
            <GenericModal
              filename={filename}
              complementaryName={complementaryName}
              message={message}
              open={openApiErrorResponse === i ? true : false}
              toggler={setOpenApiErrorResponse}
            />
          </>
        );
        break;
      case "401":
        response = (
          <>
            <Tooltip
              title="No se tienen permisos suficientes para ejecutar la operación."
              arrow
            >
              <Button
                size="small"
                variant="contained"
                color="secondary"
                onClick={async () => {
                  if (message) {
                    setOpenApiErrorResponse(true);
                  }
                }}
              >
                <strong>{state}</strong>
              </Button>
            </Tooltip>
            <GenericModal
              filename={filename}
              complementaryName={complementaryName}
              message={message}
              open={openApiErrorResponse === i ? true : false}
              toggler={setOpenApiErrorResponse}
            />
          </>
        );
        break;
      case "500":
        response = (
          <>
            <Tooltip title="Error de procesamiento en el servidor" arrow>
              <Button
                size="small"
                variant="contained"
                color="secondary"
                onClick={async () => {
                  if (message) {
                    setOpenApiErrorResponse(true);
                  }
                }}
              >
                <strong>{state}</strong>
              </Button>
            </Tooltip>
            <GenericModal
              filename={filename}
              complementaryName={complementaryName}
              message={message}
              open={openApiErrorResponse === i ? true : false}
              toggler={setOpenApiErrorResponse}
            />
          </>
        );
        break;
    }

    return response;
  };


  if ( !didMount ) { return null }

  return (
    <>
      <Paper className={classes.paperTitle}>
        <div className="flex-space-between">
          <Title>
            { COMPANY_ID && <span>{`${companyName} (${COMPANY_ID}) / Movimientos: ${CSVFilename}`}</span> }
          </Title>
          <div className="flex-align-items-center">
            <Tooltip title="Recargar datos" arrow placement="left">
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={() => {
                  getCounters(owner);
                  getTransactions(owner);
                }}
              >
                <i className="fas fa-redo"></i>
              </Button>
            </Tooltip>
          </div>
        </div>
      </Paper>
      <TableContainer component={Paper}>
        <div className="display-flex full-width">
          <div className={classes.rootTabs}>
            <div className={classes.demo2}>
              <StyledTabs
                value={valueTab}
                onChange={handleChangeTab}
                aria-label="styled tabs example"
              >
                <StyledTab
                  label={`Completados ${
                    transactionsCountByStatus.completed !== undefined
                      ? "(" + transactionsCountByStatus.completed + ")"
                      : ""
                  }`}
                />

                <StyledTab
                  label={`Activos ${
                    transactionsCountByStatus.active !== undefined
                      ? "(" + transactionsCountByStatus.active + ")"
                      : ""
                  }`}
                />

                <StyledTab
                  label={`En espera ${
                    transactionsCountByStatus.waiting !== undefined
                      ? "(" + transactionsCountByStatus.waiting + ")"
                      : ""
                  }`}
                />

                <StyledTab
                  label={`Fallo ${
                    transactionsCountByStatus.failed !== undefined
                      ? "(" + transactionsCountByStatus.failed + ")"
                      : ""
                  }`}
                />

                <StyledTab
                  label={`Demorados ${
                    transactionsCountByStatus.delayed !== undefined
                      ? "(" + transactionsCountByStatus.delayed + ")"
                      : ""
                  }`}
                />
              </StyledTabs>
              <Typography className={classes.padding} />
            </div>
          </div>
        </div>
        <Table className={classes.table} aria-label="custom pagination table">
          <TableHead className={classes.head}>
            <TableRow>
              {columns.map((column, i) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <strong>{column.label}</strong>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length === 0 ? (
              <TableRow style={{ width: 500 }}>
                <TableCell align="center" colSpan={columns.length}>
                  {"No hay resultados para esta busqueda."}
                </TableCell>
              </TableRow>
            ) : (
              rows.map((row, i) => (
                <TableRow key={row._id}>
                  <TableCell style={{ width: 50 }} align="center">
                    {row.jobId}
                  </TableCell>
                  <TableCell style={{ width: 105 }} align="center">
                    <div>{dateSeparator(row.startDatetime).hour}</div>
                    <div>{dateSeparator(row.startDatetime).fullDate}</div>
                  </TableCell>
                  <TableCell style={{ width: 100 }} align="center">
                    {getStringTime(row.duration)}
                  </TableCell>
                  <TableCell style={{ width: 80 }} align="center">
                    {row.attemptsMade}
                  </TableCell>
                  <TableCell style={{ width: 115 }} align="center">
                    {row.ticketNumber}
                  </TableCell>
                  <TableCell style={{ width: 115 }} align="center">
                    {getStatusButton(
                      CSVFilename,
                      "-nroTicket" +
                        row.ticketNumber +
                        "-status" +
                        row.apiStatusResponse,
                      row.apiStatusResponse,
                      row.apiErrorResponse,
                      i
                    )}
                  </TableCell>
                  <TableCell style={{ width: 50 }} align="center">
                    <Tooltip title="JSON" arrow>
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={async () => {
                          setOpenJSON(i);
                          setJSONShow(await axiosGetJSONTransaction(row._id));
                        }}
                      >
                        <i className="fas fa-file-code font-size-20px" />
                      </Button>
                    </Tooltip>
                    <JSONModal
                      open={openJSON === i ? true : false}
                      data={JSONShow}
                      filename={CSVFilename}
                      complementaryName={"-nroTicket" + row.ticketNumber}
                      cleanData={setJSONShow}
                      toggler={setOpenJSON}
                    />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[
                  5,
                  15,
                  30,
                  { label: "Todos", value: totalTransactions },
                ]}
                colSpan={0}
                labelRowsPerPage="Registros por pagina"
                count={totalTransactions}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}

function TablePaginationActions(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <Tooltip title="Primer página" arrow>
        <span>
          <IconButton
            onClick={handleFirstPageButtonClick}
            disabled={page === 0}
            aria-label="first page"
          >
            {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Página anterior" arrow>
        <span>
          <IconButton
            onClick={handleBackButtonClick}
            disabled={page === 0}
            aria-label="previous page"
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Siguiente página" arrow>
        <span>
          <IconButton
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="next page"
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Última página" arrow>
        <span>
          <IconButton
            onClick={handleLastPageButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="last page"
          >
            {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
          </IconButton>
        </span>
      </Tooltip>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles((theme) => ({
  table: {
    minWidth: 500,
  },
  green: {
    backgroundColor: green[600],
    color: theme.palette.background.paper,
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  yellow: {
    backgroundColor: yellow[700],
    color: grey[1000],
    "&:hover": {
      backgroundColor: yellow[700],
    },
  },
  orange: {
    backgroundColor: orange[700],
    color: grey[1000],
    "&:hover": {
      backgroundColor: orange[500],
    },
  },
  head: {
    backgroundColor: blue[100],
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  paperTitle: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    minHeight: "50px",
    maxHeight: "550px",
    marginBottom: "20px",
  },
}));

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 20,
      width: "100%",
      /* backgroundColor: '#635ee7', */
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: "#000",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(0),
    "&:focus": {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  green: {
    color: "#fff",
    backgroundColor: green[500],
  },
  rootTabs: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(0),
  },
  demo2: {
    backgroundColor: "#2e1534",
  },
  rootCheck: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(3),
  },
  colorBlack: {
    color: "#000",
  },
  rootBadge: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));
