/* https://material-ui.com/components/autocomplete/#autocomplete */

import React from 'react';
import { Autocomplete as MUIAutocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";


const Autocomplete = ({ value, inputValue, setValue, setInputValue, options }) => {
    return (
        <MUIAutocomplete
            clearOnEscape
            size="small"
            value={value}
            inputValue={inputValue}
            onChange={(event, newValue) => { setValue(newValue) }}
            onInputChange={ (event, newInputValue) => { setInputValue(newInputValue) }}
            onBlur={ ()=>{ if(inputValue === '' ) { setValue('Todas las empresas') } } }
            id="search-company-datagraph"
            options={options || []}
            getOptionLabel={ option => option }
            getOptionSelected={ option => option ?? '' }
            style={{ width: 300 }}
            renderInput={(params) => (
            <TextField
                {...params}
                label="Buscar empresa"
                variant="outlined"
            />
            )}
        />
    );
}
 
export default Autocomplete;