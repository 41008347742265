

export const stateIndexToText = (index)=>{
    switch (index) {
        case 0: return 'completed';
        case 1: return 'active';
        case 2: return 'waiting';
        case 3: return 'failed';
        case 4: return 'delayed';
    }
}

export const stateTextToIndex = (stateText)=>{
    switch (stateText) {
        case 'completed': return 0;
        case 'active': return 1;
        case 'waiting': return 2;
        case 'failed': return 3;
        case 'delayed': return 4;
    }
}

export const translateStateToSpanish = (stateText)=>{
    switch (stateText) {
        case 'completed': return 'completados';
        case 'active': return 'activos';
        case 'waiting': return 'en-espera';
        case 'failed': return 'fallo';
        case 'delayed': return 'demorados';
        default: return 'completados';
    }
}

export const translateStateToEnglish = (stateText)=>{
    switch (stateText) {
        case 'completados': return 'completed';
        case 'activos': return 'active';
        case 'en-espera': return 'waiting';
        case 'fallo': return 'failed';
        case 'demorados': return 'delayed';
        default: return 'completed';
    }
}