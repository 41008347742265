import React from 'react'
import JSONPretty from 'react-json-pretty';
import {
    Modal,
    Backdrop,
    Fade,
    Button,
    CircularProgress
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DownloadButton from './DownloadButton';
import CopyButton from './CopyButton';
const stringifyObject = require('stringify-object');




const JSONModal = ({ open, data, filename, complementaryName, toggler, cleanData }) => {

    const classes = useStyles();

    return ( 
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={ ()=>{ 
                toggler(false);
                cleanData(null);
            }}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
            timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>
                    <div className="display-flex">
                        <h2 id="transition-modal-title">{
                            data ? (
                                <> <i className="fas fa-file-code font-size-40px" /> {" JSON"} </>
                            ) : "JSON"
                        }</h2>
                        <div className="max-height-30px pt-17px">{
                            data && (
                                <>
                                    <DownloadButton 
                                        filename={filename} 
                                        complementaryName={complementaryName} 
                                        data={data} 
                                        fileType={'json'}
                                    />
                                    <CopyButton 
                                        data={stringifyObject(data, { indent: '    ', singleQuotes: false })}
                                        classes={classes}
                                    />
                                    <Button
                                        size="small" 
                                        variant="contained"
                                        color="inherit"
                                        className={classes.ml10px}
                                        onClick={ ()=>{
                                            toggler(false);
                                            cleanData(null);
                                        }} 
                                    >Cerrar</Button >
                                </>
                            )
                        }</div>
                    </div>
                    <div className="code-modal" >{
                        data ? ( <JSONPretty id="json-pretty" data={data} /> ) : (
                            <div>
                                <div className="pl-5px" > <CircularProgress /> </div>
                                <h5>Cargando</h5>
                            </div>
                        )
                    }</div>
                </div>
            </Fade>
        </Modal>
    );
}
 
export default JSONModal;



const useStyles = makeStyles((theme)=>({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    ml10px: {
        marginLeft: '10px'
    },
     
  }));


  