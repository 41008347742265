import React from 'react';
import { Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';


export const AdminListItems = ()=> {
  
  return (
    <div>
      <Link to='/dashboard' className="plain-text">
        <ListItem button>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary='Dashboard' />
        </ListItem>
      </Link>
      
      <Link to='/auditoria/completados' className="plain-text">
        <ListItem button>
          <ListItemIcon>
            <BarChartIcon />
          </ListItemIcon>
          <ListItemText primary='Auditoria' />
        </ListItem>
      </Link>

      <Link to='/empresas' className="plain-text">
        <ListItem button>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary='Empresas' />
        </ListItem>
      </Link>

    </div>
  )
}


export const CompaniesListItems = ()=> {

  return (
    <div>

      <Link to='/dashboard' className="plain-text">
        <ListItem button>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary='Dashboard' />
        </ListItem>
      </Link>

      <Link to='/auditoria/completados' className="plain-text">
        <ListItem button>
          <ListItemIcon>
            <BarChartIcon />
          </ListItemIcon>
          <ListItemText primary='Auditoria' />
        </ListItem>
      </Link>

    </div>
  )
}

