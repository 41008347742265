import React from 'react'
import {
    Modal,
    Backdrop,
    Fade,
    Button,
    CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DownloadButton from './DownloadButton';
import CopyButton from './CopyButton';



const CSVModal = ({ csv, filename, open, cleanData, toggler }) => {

    const classes = useStyles();  

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={ ()=>{ 
                toggler(false);
                cleanData(null);
            }}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
            timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>
                    <div className="display-flex ">
                        <h2 id="transition-modal-title modal-title" >
                            {
                                csv ? (
                                    <>
                                        <i className="fas fa-file-csv font-size-40px" />
                                        {` ${/* filename ||  */"CSV"}`}
                                    </>
                                ) : "CSV"
                            }                            
                        </h2>
                        <div className="max-height-30px pt-17px">
                            {
                                csv && (
                                    <>
                                        <DownloadButton filename={filename} data={csv} fileType={'csv'} />
                                        <CopyButton data={csv} classes={classes} />
                                        <Button
                                            size="small" 
                                            variant="contained"
                                            color="inherit" 
                                            className={classes.ml10px}
                                            onClick={ ()=>{
                                                toggler(false);
                                                cleanData(null);
                                            }} 
                                        >Cerrar</Button>
                                    </>
                                )
                            }
                        </div>
                    </div>
                    <div className="code-modal" >
                    {
                        csv ? (
                            <pre>{csv}</pre>
                        ) : (
                            <div>
                                <div className="pl-5px" > 
                                <CircularProgress />
                                </div>
                                <h5>Cargando</h5>
                            </div>
                        )
                    }
                    </div>
                </div>
            </Fade>
        </Modal>
    );
}
 
export default CSVModal;


const useStyles = makeStyles((theme)=>({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    ml10px: {
        marginLeft: '10px'
    }
}));



