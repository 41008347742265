import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import history from '../../helpers/history';
import { setUserActiveAction, setEmptyStoreAction } from '../../redux/actions/userActions';
import { 
  removeSessionToken, 
  hasExpiredSession, 
  removeExpiredSession,
  setSessionToken
} from '../../helpers/sessionStorage';
import {
  Button,
  CssBaseline,
  TextField,
  Container,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Error from '../../components/MessageBoxes/Error/Error';
import { axiosTryLogIn } from '../../helpers/servicesModule';
import jwt_decode from 'jwt-decode';


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));



export default function LogIn() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [didMount, setDidMount] = useState(false); 

  useEffect(() => {
   setDidMount(true);
   return () => setDidMount(false);
  }, [])


  const initialState = {
    username: "",
    password: ""
  }
  
  const errorState = {
    state: false,
    message: ""
  }

  const [userData, updateUserData] = useState(initialState);
  const [triedSubmit, updateTriedSubmit] = useState(false);
  const [error, updateError] = useState(errorState);
  const [loading, updateLoading] = useState(false);

  let { username, password } = userData;

  useEffect( ()=>{
    if ( triedSubmit && ( !username || !password ) )
    {
      updateError({state: true, message: "Compruebe los datos ingresados."});
    } else {
      updateError({state: false, message: ""});
    }
  }, [userData, triedSubmit, username, password]);

  useEffect( ()=>{
    if ( hasExpiredSession() ) {
      updateError({state: true, message: 'La sesión ha expirado.'});
      setTimeout( ()=>{ updateError({state: false, message: ''}) }, 5000 )
    }
  }, [])
  
  
  
  const changeHandler = event =>{
    updateUserData({
      ...userData,
      [event.target.name]: event.target.value
    })
  }

  const isSubmitDisabled = ()=>{
    let response = true;
    
    if ( username && password && !loading ) {
      response = false;
    }

    return response;
  }

  const submitHandler = async (event) => {
    event.preventDefault();
    updateTriedSubmit(true);
    updateError({state: false, message: ""});
    updateLoading(true);


    setTimeout( async ()=>{
      const newSessionToken = await axiosTryLogIn(history, userData);

      if ( newSessionToken && newSessionToken.error ) {
        // There is no token, because the credentials are invalid.
        updateError({state: true, message: newSessionToken.error.message});
      } else {
        removeExpiredSession();
        setSessionToken(newSessionToken);
        let decoded = jwt_decode(newSessionToken);

        dispatch( setUserActiveAction({ 
          sub: decoded.sub,
          enabled: decoded.enabled,
          isAdmin: decoded.isAdmin,
          username: decoded.username,
          companyName: decoded.companyName
        }));

        history.push(`/dashboard`);
      } 
      updateLoading(false);
    }, 1000);

    
  }

  const removeSessionData = ()=>{
    dispatch( setEmptyStoreAction() );  // All current user data is removed from the redux store.
    removeSessionToken();
  }

  if(!didMount) {
    return null;
  }

  return (
    <>
      { removeSessionData() }
      <Container component="main" maxWidth="xs">
        <img 
          className="logo-login" 
          src="/logos/Logo-Scanntech-01.png"
          alt="Scanntech"
          /* src="https://scanntech.com/wp-content/uploads/2017/12/Logo-Scanntech-01.png" */
        />
        <CssBaseline />
        <div className={classes.paper}>
          
          <form className={classes.form} >
            <TextField
              type="number"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Código de empresa"
              value={username}
              name="username"
              autoFocus
              onChange={ changeHandler } 
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Contraseña"
              value={password}
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={ changeHandler } 
            />
            
            { error.state && ( <Error message={error.message} /> ) }
            <Button
              disabled={ isSubmitDisabled() }
              type="submit"
              fullWidth
              mt={8}
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={ submitHandler }
            >{
              loading ? (
                <CircularProgress size={24} />
              ) : (
                "Ingresar"
              )
            }</Button>
          </form>
        </div>
        
      </Container>
    </>
    
  );
}