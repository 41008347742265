import React from "react";
import MainLayout from "../../components/Layouts/MainLayout/MainLayout";
import FormEditUser from "./FormEditCompany";

const EditCompany = () => {
  return (
    <>
      <MainLayout title={"Empresas"}>
        <FormEditUser />
      </MainLayout>
    </>
  );
};

export default EditCompany;
