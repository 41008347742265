import React from "react";
import MainLayout from "../../components/Layouts/MainLayout/MainLayout";
import FormNewUser from "./FormNewCompany";

const CreateUser = () => {
  return (
    <>
      <MainLayout title={"Empresas"}>
        <FormNewUser />
      </MainLayout>
    </>
  );
};

export default CreateUser;
