import React from "react";
import MainLayout from "../../components/Layouts/MainLayout/MainLayout";
import CompanyTable from "./CompanyTable";

const Companies = () => {
  return (
    <MainLayout title={"Empresas"}>
      <CompanyTable />
    </MainLayout>
  );
};

export default Companies;
