import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { green, grey, blue, yellow, orange } from "@material-ui/core/colors";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Button,
  Tooltip,
  Tabs,
  Tab,
  Typography,
} from "@material-ui/core";
import { dateSeparator } from "../../../helpers/dates";
import Title from "../../../components/Layouts/MainLayout/Title";
import {
  axiosTransactionFind,
  axiosGetJSONTransaction,
  axiosGetTransactionsCountByStatus,
  axiosGetAuditFilename,
  axiosGetCompaniesUsefulLists,
} from "../../../helpers/servicesModule";
import { getStringTime } from "../../../helpers/times";
import GenericModal from "../../../components/Modals/GenericModal";
import JSONModal from "../../../components/Modals/JSONModal";
import {
  stateIndexToText,
  stateTextToIndex,
  translateStateToEnglish,
  translateStateToSpanish,
} from "../../../helpers/statusTabsTools";

export default function CloseTable({
  COMPANY_ID,
  OWNER,
  STATUS,
}) {
  const classes = useStyles();
  const [companyName, setCompanyName] = useState("");
  const [transactions, updateTransactions] = useState([]);
  const [CSVFilename, setCSVFilename] = useState("Cargando...");
  const [JSONShow, setJSONShow] = useState(null);
  const [openJSON, setOpenJSON] = useState(false);
  const [openApiErrorResponse, setOpenApiErrorResponse] = useState(false);

  const auxValueTab = STATUS
    ? stateTextToIndex(translateStateToEnglish(STATUS))
    : 0;
  const [valueTab, setValueTab] = useState(auxValueTab);
  const [transactionsCountByStatus, updateTransactionsCountByStatus] = useState({});

  const [didMount, setDidMount] = useState(false); 

  useEffect(() => {
   setDidMount(true);
   return () => setDidMount(false);
  }, [])

  useEffect(() => {
    const auxAsyncFunction = async () => {
      setCSVFilename(await axiosGetAuditFilename(OWNER));
      let response = await axiosGetCompaniesUsefulLists();

      if (!response.error && response.data) {
        let auxCompanyName = response.data.filter(
          (obj) => obj.companyID === COMPANY_ID
        )[0];
        if (auxCompanyName) {
          setCompanyName(auxCompanyName.companyName);
        }
      }
    };

    auxAsyncFunction();
  }, []);

  useEffect(() => {
    const auxAsyncFunction = async () => {
      getTransactions();
    };

    auxAsyncFunction();
    getCounters();

    window.history.replaceState(
      null,
      null,
      `/auditoria/${COMPANY_ID}/cierre/${OWNER}/${translateStateToSpanish(
        stateIndexToText(valueTab)
      )}`
    );
  }, [valueTab]);

  const getCounters = async ()=>{
    let response = await axiosGetTransactionsCountByStatus(OWNER);
      let states = {};

    if (!response.error) {
      response.map((objDataState) => {
        states[objDataState.status] = objDataState.count;
      });

      updateTransactionsCountByStatus(states);
    }
  }

  const getTransactions = async () => {
    let response = await axiosTransactionFind(
      OWNER,
      stateIndexToText(valueTab),
      0, // page
      1  //rowsPerPage
    );

    if (response) {
      if (!response.error) {
        updateTransactions(response.audits);
      }
    }
  };

  const handleChangeTab = (event, newValueTab) => {
    setValueTab(newValueTab);
  };

  const columns = [
    { id: "idColumna1", label: "JobID", minWidth: 25, align: "center" },
    { id: "idColumna7", label: "Fecha inicio", minWidth: 50, align: "center" },
    { id: "idColumna8", label: "Duración", minWidth: 50, align: "center" },
    { id: "idColumna9", label: "Reintentos", minWidth: 50, align: "center" },
    { id: "idColumna10", label: "Estado", minWidth: 50, align: "center" },
    { id: "idColumna11", label: "Acciones", minWidth: 50, align: "center" },
  ];

  const rows = [...transactions];

  const getStatusButton = (filename, state, message, i) => {
    /* http://confluence.scanntech.com/pages/viewpage.action?pageId=122589143 */
    let response = "";

    switch (state) {
      case "200":
        response = (
          <Tooltip title="Operación realizada con éxito" arrow>
            <Button
              size="small"
              variant="contained"
              className={`${classes.green} pointer-none`}
            >
              <strong>{state}</strong>
            </Button>
          </Tooltip>
        );
        break;
      case "208":
        response = (
          <Tooltip title="La operación ya fue procesada anteriormente" arrow>
            <Button
              size="small"
              variant="contained"
              className={`${classes.yellow} pointer-none`}
            >
              <strong>{state}</strong>
            </Button>
          </Tooltip>
        );
        break;
      case "400":
        response = (
          <>
            <Tooltip title="Los datos suministrados son incorrectos" arrow>
              <Button
                size="small"
                variant="contained"
                className={classes.orange}
                onClick={async () => {
                  if (message) {
                    setOpenApiErrorResponse(i);
                  }
                }}
              >
                <strong>{state}</strong>
              </Button>
            </Tooltip>
            <GenericModal
              state={state}
              message={message}
              open={openApiErrorResponse === i ? true : false}
              toggler={setOpenApiErrorResponse}
            />
          </>
        );
        break;
      case "401":
        response = (
          <>
            <Tooltip
              title="No se tienen permisos suficientes para ejecutar la operación."
              arrow
            >
              <Button
                size="small"
                variant="contained"
                color="secondary"
                onClick={async () => {
                  if (message) {
                    setOpenApiErrorResponse(true);
                  }
                }}
              >
                <strong>{state}</strong>
              </Button>
            </Tooltip>
            <GenericModal
              filename={filename}
              state={state}
              message={message}
              open={openApiErrorResponse}
              toggler={setOpenApiErrorResponse}
            />
          </>
        );
        break;
      case "500":
        response = (
          <>
            <Tooltip title="Error de procesamiento en el servidor" arrow>
              <Button
                size="small"
                variant="contained"
                color="secondary"
                onClick={async () => {
                  if (message) {
                    setOpenApiErrorResponse(true);
                  }
                }}
              >
                <strong>{state}</strong>
              </Button>
            </Tooltip>
            <GenericModal
              filename={filename}
              state={state}
              message={message}
              open={openApiErrorResponse}
              toggler={setOpenApiErrorResponse}
            />
          </>
        );
        break;
    }

    return response;
  };


  if ( !didMount ) { return null }

  return (
    <>
      <Paper className={classes.paperTitle}>
        <div className="flex-space-between">
          <Title>
            { COMPANY_ID && <span>{`${companyName} (${COMPANY_ID}) / Cierre: ${CSVFilename}`}</span> }
          </Title>
          <div className="flex-align-items-center">
            <Tooltip title="Recargar datos" arrow placement="left">
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={() => {
                  getCounters();
                  getTransactions();
                }}
              >
                <i className="fas fa-redo"></i>
              </Button>
            </Tooltip>
          </div>
        </div>
      </Paper>
      <TableContainer component={Paper}>
        <div className="display-flex full-width">
          <div className={classes.rootTabs}>
            <div className={classes.demo2}>
              <StyledTabs
                value={valueTab}
                onChange={handleChangeTab}
                aria-label="styled tabs example"
              >
                <StyledTab
                  label={`Completados ${
                    transactionsCountByStatus.completed !== undefined
                      ? "(" + transactionsCountByStatus.completed + ")"
                      : ""
                  }`}
                />

                <StyledTab
                  label={`Activos ${
                    transactionsCountByStatus.active !== undefined
                      ? "(" + transactionsCountByStatus.active + ")"
                      : ""
                  }`}
                />

                <StyledTab
                  label={`En espera ${
                    transactionsCountByStatus.waiting !== undefined
                      ? "(" + transactionsCountByStatus.waiting + ")"
                      : ""
                  }`}
                />

                <StyledTab
                  label={`Fallo ${
                    transactionsCountByStatus.failed !== undefined
                      ? "(" + transactionsCountByStatus.failed + ")"
                      : ""
                  }`}
                />

                <StyledTab
                  label={`Demorados ${
                    transactionsCountByStatus.delayed !== undefined
                      ? "(" + transactionsCountByStatus.delayed + ")"
                      : ""
                  }`}
                />
              </StyledTabs>
              <Typography className={classes.padding} />
            </div>
          </div>
        </div>
        <Table className={classes.table} aria-label="custom pagination table">
          <TableHead className={classes.head}>
            <TableRow>
              {columns.map((column, i) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <strong>{column.label}</strong>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length === 0 ? (
              <TableRow style={{ width: 500 }}>
                <TableCell align="center" colSpan={columns.length}>
                  {"No hay resultados para esta busqueda."}
                </TableCell>
              </TableRow>
            ) : (
              rows.map((row, i) => (
                <TableRow key={row._id}>
                  <TableCell style={{ width: 50 }} align="center">
                    {row.jobId}
                  </TableCell>
                  <TableCell style={{ width: 105 }} align="center">
                    <div>{dateSeparator(row.startDatetime).hour}</div>
                    <div>{dateSeparator(row.startDatetime).fullDate}</div>
                  </TableCell>
                  <TableCell style={{ width: 100 }} align="center">
                    {getStringTime(row.duration)}
                  </TableCell>
                  <TableCell style={{ width: 80 }} align="center">
                    {row.attemptsMade}
                  </TableCell>
                  <TableCell style={{ width: 115 }} align="center">
                    {getStatusButton(
                      CSVFilename,
                      row.apiStatusResponse,
                      row.apiErrorResponse,
                      i
                    )}
                  </TableCell>
                  <TableCell style={{ width: 50 }} align="center">
                    <Tooltip title="JSON" arrow>
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={async () => {
                          setOpenJSON(i);
                          setJSONShow(await axiosGetJSONTransaction(row._id));
                        }}
                      >
                        <i className="fas fa-file-code font-size-20px" />
                      </Button>
                    </Tooltip>
                    <JSONModal
                      open={openJSON === i ? true : false}
                      data={JSONShow}
                      filename={CSVFilename}
                      cleanData={setJSONShow}
                      toggler={setOpenJSON}
                    />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}



const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 500,
  },
  green: {
    backgroundColor: green[600],
    color: theme.palette.background.paper,
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  yellow: {
    backgroundColor: yellow[700],
    color: grey[1000],
    "&:hover": {
      backgroundColor: yellow[700],
    },
  },
  orange: {
    backgroundColor: orange[700],
    color: grey[1000],
    "&:hover": {
      backgroundColor: orange[500],
    },
  },
  head: {
    backgroundColor: blue[100],
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  paperTitle: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    minHeight: "50px",
    maxHeight: "550px",
    marginBottom: "20px",
  },
}));


const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 20,
      width: "100%",
      /* backgroundColor: '#635ee7', */
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: "#000",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(0),
    "&:focus": {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);