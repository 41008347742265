import React, { useState } from 'react';
import { Button, Tooltip } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';




const DownloadButton = ({ filename, complementaryName, data, fileType }) => {

    const classes = useStyles();
    const [downloading, setDownloading] = useState(false); 
    
    filename = filename ? filename.slice(0, -4) : '' // Se le quita .xxx que define el tipo de dato por defecto...
    filename = `${filename}-${complementaryName ? complementaryName : ''}.${fileType}`;;
    // Se añade al string original (nombre del archivo csv), nombre complementario si lo hubiera y el tipo de dato 'nombre.xxx'
    // Al añadir un nombre de archivo, el tipo parece perder relevancia,
    // por eso es necesario anadir al nombre el tipo de archivo como se hace en las lineas anteriores.
    // Si el nombre de archivo necesita datos extras, estos llegarán en 'complementaryName'
    // El nombre complementario puede contener datos de, por ejemplo, numero de ticket, estado, etc

    return (
        <Tooltip title={downloading ? 'Descargando...' : 'Descargar'} className={downloading ? classes.green : ''} >
            <Button
                size="large" 
                variant="contained"
                color="primary"
                className={classes.buttonSpacing}
                onClick={ ()=>{
                    setDownloading(true);
                    setTimeout( ()=>{ setDownloading(false) }, 3000 );
                    let auxData = new Blob([JSON.stringify(data)], {type: `text/${fileType}`});
                    let dataURL = window.URL.createObjectURL(auxData);
                    const tempLink = document.createElement('a');
                    tempLink.href = dataURL;
                    tempLink.setAttribute('download', filename);
                    tempLink.click();
                }}
            ><i className="fas fa-cloud-download-alt" /></Button>
        </Tooltip>
    );
}
 
export default DownloadButton;


const useStyles = makeStyles((theme)=>({
    buttonSpacing: {
        marginRight: '10px',
        marginLeft: '110px'
    },
    green: {
        backgroundColor: green[700],
        '&:hover': {
            backgroundColor: green[800],
        }
    }
}));